import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  FlexDirection,
  JustifyContent,
} from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Disableable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { IconName } from '@ac/web-components';

export interface MaintenanceDetailsFooterProps extends Childless, Disableable {
  maintenanceRoom: MaintenanceRoom;
}

const MaintenanceDetailsFooter: FC<MaintenanceDetailsFooterProps> = (
  props: MaintenanceDetailsFooterProps
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const testSelector = 'maintenance-details-footer';
  const isMaintenanceOpen = !props.maintenanceRoom.hasResolvedState;
  const maintenanceState = props.maintenanceRoom.state?.code;

  const closeMaintenanceHandler = useCallback(() => {
    navigate(
      generatePath(APP_ROUTES.MAINTENANCE.RELEASE, {
        id: props.maintenanceRoom.id,
      })
    );
  }, [props.maintenanceRoom, navigate]);

  const cloneMaintenanceHandler = useCallback(() => {
    navigate(
      generatePath(APP_ROUTES.MAINTENANCE.CLONE, {
        id: props.maintenanceRoom.id,
      })
    );
  }, [props.maintenanceRoom, navigate]);

  return (
    <ConnectedFooter noPadding={true}>
      <AcFlex
        direction={FlexDirection.row}
        justifyContent={JustifyContent.center}
      >
        {isMaintenanceOpen && (
          <AcButton
            onClick={closeMaintenanceHandler}
            pattern={AcButtonPattern.Tertiary}
            testSelector={`${testSelector}-close-maintenance`}
            disabled={props.disabled}
            fullwidth={true}
          >
            <AcButtonContent
              icon={IconName.reassignRoom}
              text={t(`MAINTENANCE_RELEASE.${maintenanceState}.TITLE.SHORT`)}
            />
          </AcButton>
        )}

        <AcButton
          onClick={cloneMaintenanceHandler}
          pattern={AcButtonPattern.Tertiary}
          testSelector={`${testSelector}-clone-maintenance`}
          disabled={props.disabled}
          fullwidth={true}
        >
          <AcButtonContent
            icon={IconName.duplicate}
            text={t('MAINTENANCE_COPY.TITLE')}
          />
        </AcButton>
      </AcFlex>
    </ConnectedFooter>
  );
};

export default memo(MaintenanceDetailsFooter);
