import React, { FC, memo } from 'react';
import MaintenanceDashboardQuickFilter from '@hkm/components/Maintenance/Dashboard/SubHeader/QuickFilter/MaintenanceDashboardQuickFilter';
import MaintenanceDashboardSortSelect from '@hkm/components/Maintenance/Dashboard/SubHeader/SortSelector/MaintenanceDashboardSortSelect';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcSubheader } from '@ac/mobile-components/dist/components/layout';
import { AlignItems, JustifyContent } from '@ac/mobile-components/dist/enums';

import './MaintenanceDashboardSubHeader.css';

const MaintenanceDashboardSubHeader: FC = () => {
  const prefix = 'maintenance-dashboard-subheader';

  return (
    <AcSubheader className={prefix} noPadding={true}>
      <MaintenanceDashboardQuickFilter className="ac-spacing-top-sm" />
      <AcFlex
        className={`${prefix}-option`}
        justifyContent={JustifyContent.flexEnd}
        alignItems={AlignItems.center}
      >
        <MaintenanceDashboardSortSelect />
      </AcFlex>
    </AcSubheader>
  );
};

export default memo(MaintenanceDashboardSubHeader);
