import React, { HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectActiveProperty,
  selectEffectiveValues,
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DatetimeFormElement, {
  DatetimeVariant,
} from '@hkm/components/shared/DatetimeFormElement/DatetimeFormElement';
import { GuestServiceRoom } from '@hkm/components/shared/GuestService/GuestServiceField';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import { createLocalTimeFromTzTime } from '@hkm/utils/dateHelper';
import classNames from 'classnames';

import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';

import './GuestServiceBadge.css';

interface Props extends HTMLAttributes<HTMLDivElement>, Testable {
  room: GuestServiceRoom;
  flexDirection?: FlexDirection;
}

export const GuestServiceBadge = ({
  testSelector,
  className: classNameProp,
  room,
  flexDirection,
  ...rest
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const className = classNames('service-deferred-badge', classNameProp);
  const effectiveValues = useSelector(selectEffectiveValues);
  const property = useSelector(selectActiveProperty);
  const deferredTime = createLocalTimeFromTzTime(
    room.serviceDeferredUntil,
    property?.timeZoneCode
  );
  const preferredTime = createLocalTimeFromTzTime(
    room.servicePreferredAfterTime,
    property?.timeZoneCode
  );

  const isHousekeepingCleaningScheduleEnabled =
    effectiveValues?.enabledHousekeepingSchedule;
  const { isServiceActive } = useGuestServices();

  const shouldShowGuestServiceBadge = useMemo(() => {
    if (isHousekeepingCleaningScheduleEnabled) {
      return (
        room.serviceDeferred ||
        room.servicePreferred ||
        room.serviceRequested ||
        room.doNotDisturb ||
        room.serviceRefused
      );
    } else {
      return room.serviceDeferred;
    }
  }, [isHousekeepingCleaningScheduleEnabled, room]);

  if (!shouldShowGuestServiceBadge) {
    return null;
  }

  return (
    <AcFlex className={className} direction={flexDirection} {...rest}>
      {!isHousekeepingCleaningScheduleEnabled ? (
        room.serviceDeferred && (
          <AcFlex
            className={className}
            alignItems={AlignItems.center}
            {...rest}
          >
            <AcBadge
              borderColor={MobileColor.Gray4}
              backgroundColor={MobileColor.White}
              className="service-deferred-badge-element"
              {...getTestSelectorProp(testSelector, 'service-deferred')}
              badgeText={
                isServiceActive(GuestServiceStatusType.ServiceDeferred)
                  ? t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceDeferred')
                  : t(
                      'GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceDeferred_inactive'
                    )
              }
            />
            {deferredTime && (
              <DatetimeFormElement
                className="ac-spacing-left-sm"
                smallText={true}
                timeLabel={t('GLOBAL.GUEST_SERVICE_STATUS.PREFERRED_TIME')}
                color={MobileColor.ServiceDeferred}
                variant={DatetimeVariant.onlyTime}
                datetime={deferredTime}
                flatAndJustify={JustifyContent.center}
              />
            )}
          </AcFlex>
        )
      ) : (
        <>
          {room.serviceDeferred && (
            <AcFlex>
              <AcBadge
                borderColor={MobileColor.Gray4}
                backgroundColor={MobileColor.White}
                className="service-deferred-badge-element"
                {...getTestSelectorProp(testSelector, 'service-deferred')}
                badgeText={
                  isServiceActive(GuestServiceStatusType.ServiceDeferred)
                    ? t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceDeferred')
                    : t(
                        'GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceDeferred_inactive'
                      )
                }
              />
              {deferredTime && (
                <DatetimeFormElement
                  className="ac-spacing-left-sm"
                  smallText={true}
                  timeLabel={t('GLOBAL.GUEST_SERVICE_REQUEST.CLEAN_AFTER')}
                  color={MobileColor.ServiceDeferred}
                  variant={DatetimeVariant.onlyTime}
                  datetime={deferredTime}
                  flatAndJustify={JustifyContent.center}
                />
              )}
            </AcFlex>
          )}
          {room.servicePreferred && (
            <AcFlex>
              <AcBadge
                borderColor={MobileColor.Gray4}
                backgroundColor={MobileColor.White}
                className="service-deferred-badge-element"
                {...getTestSelectorProp(testSelector, 'service-preferred')}
                badgeText={
                  isServiceActive(GuestServiceStatusType.ServicePreferred)
                    ? t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.servicePreferred')
                    : t(
                        'GLOBAL.GUEST_SERVICE_STATUS.VALUES.servicePreferred_inactive'
                      )
                }
              />
              {preferredTime && (
                <DatetimeFormElement
                  className="ac-spacing-left-sm"
                  smallText={true}
                  timeLabel={t('GLOBAL.GUEST_SERVICE_STATUS.PREFERRED_TIME')}
                  color={MobileColor.ServiceDeferred}
                  variant={DatetimeVariant.onlyTime}
                  datetime={preferredTime}
                  flatAndJustify={JustifyContent.center}
                />
              )}
            </AcFlex>
          )}
        </>
      )}
      {room.serviceRequested && (
        <AcFlex>
          <AcBadge
            borderColor={MobileColor.Gray4}
            backgroundColor={MobileColor.White}
            className="service-deferred-badge-element"
            {...getTestSelectorProp(testSelector, 'service-requested')}
            badgeText={
              isServiceActive(GuestServiceStatusType.ServiceRequested)
                ? t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRequested')
                : t(
                    'GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRequested_inactive'
                  )
            }
          />
          {room.serviceRequestedInstruction && (
            <AcText
              size={TextSize.Main2}
              className="ac-spacing-left-sm"
              {...getTestSelectorProp(
                testSelector,
                'service-requested-instruction'
              )}
            >
              {t('GLOBAL.GUEST_SERVICE_REQUEST.INSTRUCTION_INCLUDED')}
            </AcText>
          )}
        </AcFlex>
      )}
      {room.doNotDisturb && (
        <AcFlex>
          <AcBadge
            borderColor={MobileColor.Gray4}
            backgroundColor={MobileColor.White}
            className="service-deferred-badge-element"
            {...getTestSelectorProp(testSelector, 'do-not-disturb')}
            badgeText={
              isServiceActive(GuestServiceStatusType.DoNotDisturb)
                ? t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.doNotDisturb')
                : t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.doNotDisturb_inactive')
            }
          />
        </AcFlex>
      )}
      {room.serviceRefused && (
        <AcFlex>
          <AcBadge
            borderColor={MobileColor.Gray4}
            backgroundColor={MobileColor.White}
            className="service-deferred-badge-element"
            {...getTestSelectorProp(testSelector, 'service-refused')}
            badgeText={
              isServiceActive(GuestServiceStatusType.ServiceRefused)
                ? t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRefused')
                : t(
                    'GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRefused_inactive'
                  )
            }
          />
        </AcFlex>
      )}
    </AcFlex>
  );
};
