import React, { FC, memo } from 'react';
import { StatisticsSectionData } from '@hkm/components/shared/StatisticsSection/StatisticsSectionData';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

import './StatisticsSection.css';

export interface StatisticsSectionProps extends Childless {
  data: StatisticsSectionData;
  testSelector?: string;
}

const StatisticsSection: FC<StatisticsSectionProps> = ({
  data,
  testSelector,
}) => {
  return (
    // tslint:disable-next-line:jsx-no-lambda
    <AcTile
      className="statistics-section"
      data-test-selector={testSelector}
      customHeaderTemplate={
        <AcFlex
          className="statistics-section-header"
          justifyContent={JustifyContent.spaceBetween}
          alignItems={AlignItems.center}
        >
          <AcFlex
            direction={FlexDirection.column}
            justifyContent={JustifyContent.center}
          >
            <AcText
              size={TextSize.Main1}
              testSelector={`${testSelector}-title`}
            >
              {data.title}
            </AcText>
            <AcText
              size={TextSize.Main2}
              weight={TextWeight.Semibold}
              testSelector={`${testSelector}-subtitle`}
            >
              {data.subtitle}
            </AcText>
          </AcFlex>
          <AcText
            size={TextSize.H1}
            weight={TextWeight.Light}
            equalLineHeight={true}
          >
            {data.mainValue}
          </AcText>
        </AcFlex>
      }
    >
      <div className="statistics-section-body">
        {data.items.map((item, index) => (
          <AcFlex
            key={index}
            className="statistics-section-item"
            testSelector={`${testSelector}-item`}
            justifyContent={JustifyContent.spaceBetween}
            alignItems={AlignItems.center}
          >
            <AcText size={TextSize.Main2}>{item.label}</AcText>
            <AcText size={TextSize.Main1}>{item.value}</AcText>
          </AcFlex>
        ))}
      </div>
    </AcTile>
  );
};

export default memo(StatisticsSection);
