import * as appActions from '@hkm/components/App/domain/actions';
import { MaintenancePagination } from '@hkm/components/Maintenance/Dashboard/domain/interfaces';
import { selectMaintenanceDashboardPagination } from '@hkm/components/Maintenance/Dashboard/domain/selectors';
import * as detailsActions from '@hkm/components/Maintenance/Details/domain/actions';
import { MaintenanceDetailsState } from '@hkm/components/Maintenance/Details/domain/interfaces';
import { selectMaintenanceDetailsState } from '@hkm/components/Maintenance/Details/domain/selectors';
import { RoomsNotFoundException } from '@hkm/shared/errors/ErrorException';
import { getMaintenanceRoomById } from '@hkm/shared/helpers/api/getMaintenanceRoomById';
import { getHousekeepingRoomVersionId } from '@hkm/shared/services/getHousekeepingRoomVersionId';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';
import {
  cancel,
  put,
  select,
  take,
  takeLatest,
} from '@redux-saga/core/effects';
import { Task } from '@redux-saga/types';
import i18n from 'i18next';

import {
  getDataForAllPages,
  MaintenanceFile,
  PageResponseDto,
} from '@ac/library-api';
import { HousekeepingRoomsApi } from '@ac/library-api/dist/api/v0/housekeeping';
import { repeatableCall } from '@ac/library-utils/dist/utils';

function* fetchMaintenanceRoom() {
  // todo replace with fetch by id once endpoint exists, no need to check for versionId after we change the endpoint
  try {
    const detailsState: MaintenanceDetailsState = yield select(
      selectMaintenanceDetailsState
    );
    const maintenanceId: string = detailsState.roomId ?? '';
    const isRefreshingRoom: boolean = !!detailsState.room;
    const currentVersion: number = isRefreshingRoom
      ? yield getHousekeepingRoomVersionId(
          detailsState.room?.roomNumber?.id ?? ''
        )
      : -1;

    const checkNewForNewVersion = (maintenanceRoomToCheck: MaintenanceRoom) => {
      return maintenanceRoomToCheck.housekeepingRoomVersion >= currentVersion;
    };

    const maintenanceRoom: MaintenanceRoom = yield repeatableCall(
      () => getMaintenanceRoomById(maintenanceId),
      checkNewForNewVersion
    );

    yield put(detailsActions.fetchMaintenanceRoom.success(maintenanceRoom));
    yield put(detailsActions.fetchAttachments.trigger());
  } catch (e) {
    if (e instanceof RoomsNotFoundException) {
      yield put(appActions.displayError(i18n.t(e.message)));
    } else {
      yield put(appActions.displayError(i18n.t('ROOM_DETAILS.REFRESH_FAIL')));
    }

    yield put(detailsActions.fetchMaintenanceRoom.failure(e));
  }
}

function* fetchAttachments() {
  const detailsState: MaintenanceDetailsState = yield select(
    selectMaintenanceDetailsState
  );
  const roomDetails = detailsState.room;

  try {
    const response: PageResponseDto<MaintenanceFile> = yield getDataForAllPages(
      ({ pageNumber, pageSize }) =>
        HousekeepingRoomsApi.getRoomMaintenancesFileList({
          pathParams: {
            roomId: roomDetails?.roomNumber?.id ?? '',
            maintenanceId: roomDetails?.id ?? '',
          },
          queryParams: {
            pageNumber,
            pageSize,
          },
        }) as Promise<PageResponseDto<MaintenanceFile>>
    );

    const sorted = response.results.sort((a, b) =>
      a.metadata.createdAt > b.metadata.createdAt ? 1 : -1
    );

    yield put(detailsActions.fetchAttachments.success(sorted));
  } catch (e) {
    yield put(detailsActions.fetchAttachments.failure(e));
    yield put(appActions.displayError(e.message));
  }
}

function* handleInitialize() {
  yield takeLatest(
    detailsActions.fetchMaintenanceRoom.trigger,
    fetchMaintenanceRoom
  );
  yield takeLatest(detailsActions.fetchAttachments.trigger, fetchAttachments);

  // Clone from dashboard or fetch new
  const detailsState: MaintenanceDetailsState = yield select(
    selectMaintenanceDetailsState
  );
  const dashboardState: MaintenancePagination = yield select(
    selectMaintenanceDashboardPagination
  );
  const foundRoom: MaintenanceRoom | undefined = (
    dashboardState.page ? dashboardState.page.results : []
  ).find((dashboardRoom) => dashboardRoom.id === detailsState.roomId);

  if (foundRoom) {
    yield put(detailsActions.setMaintenanceRoomFromDashboard(foundRoom));
    yield put(detailsActions.fetchAttachments.trigger());
  } else {
    yield put(detailsActions.fetchMaintenanceRoom.trigger());
  }
}

export default function* MaintenanceDetailsSagas() {
  while (true) {
    const task: Task = yield takeLatest(
      detailsActions.initialize,
      handleInitialize
    );
    yield take(detailsActions.reset);
    yield cancel(task);
  }
}
