import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { RoomOccupancy } from '@hkm/shared/roomOccupancy/roomOccupancy';

import { AcFieldNumber } from '@ac/mobile-components/dist/components/field';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcModalBody } from '@ac/mobile-components/dist/components/modal';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import {
  Changeable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

import './OccupancyDiscrepancyModalBody.css';

export interface OccupancyDiscrepancyModalBodyChange extends GuestCount {
  valid: boolean;
}

export interface HousekeepingOccupancyModalProps
  extends Testable,
    Changeable<OccupancyDiscrepancyModalBodyChange> {
  occupancy: RoomOccupancy;
}

const OccupancyDiscrepancyModalBody: FC<HousekeepingOccupancyModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const [adults, setAdults] = useState<number>();
  const [children, setChildren] = useState<number>();

  const didChange = useCallback(
    () =>
      props.occupancy.reportedAdults !== adults ||
      props.occupancy.reportedChildren !== children,
    [props.occupancy, adults, children]
  );

  const hasZeroGuests = useCallback(
    () => (adults || 0) + (children || 0) === 0,
    [adults, children]
  );

  const isValid = useCallback(
    () => !hasZeroGuests() && didChange(),
    [didChange, hasZeroGuests]
  );

  const onChange = useCallback(() => {
    props.onChange?.({
      childCount: children || 0,
      adultCount: adults || 0,
      valid: isValid(),
    });
  }, [props, adults, children, isValid]);

  const onAdultsChange = useCallback(
    (count: number) => {
      setAdults(count);
      onChange();
    },
    [onChange]
  );

  const onChildrenChange = useCallback(
    (count: number) => {
      setChildren(count);
      onChange();
    },
    [onChange]
  );

  const onAdultsBlur = useCallback(() => {
    if (adults === undefined) {
      setAdults(0);
    }
  }, [adults]);

  const onChildrenBlur = useCallback(() => {
    if (children === undefined) {
      setChildren(0);
    }
  }, [children]);

  useEffect(() => {
    onChange();

    // eslint-disable-next-line
  }, [props.occupancy, adults, children]);

  useEffect(() => {
    setAdults(props.occupancy.reportedAdults);
    setChildren(props.occupancy.reportedChildren);
  }, [props.occupancy]);

  return (
    <AcModalBody className="occupancy-discrepancy-modal-body">
      <AcText color={MobileColor.Black} size={TextSize.Main1}>
        {t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.DESCRIPTION')}
      </AcText>

      <AcFlex
        alignItems={AlignItems.flexEnd}
        className="occupancy-discrepancy-modal-inputs ac-spacing-top-sm"
      >
        <AcFieldNumber
          className="ac-spacing-right-md"
          label={t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.ADULTS_LABEL')}
          placeholder={t('GLOBAL.FILL')}
          min={0}
          max={999}
          autocomplete={false}
          integer={true}
          onChange={onAdultsChange}
          onBlur={onAdultsBlur}
          value={adults}
          testSelector={formatTestSelector(props.testSelector, 'adultsCount')}
        />
        <AcFieldNumber
          className="ac-spacing-left-md"
          label={t('ROOM_DETAILS.HOUSEKEEPING_OCCUPANCY.CHILDREN_LABEL')}
          placeholder={t('GLOBAL.FILL')}
          min={0}
          max={999}
          autocomplete={false}
          integer={true}
          onChange={onChildrenChange}
          onBlur={onChildrenBlur}
          value={children}
          testSelector={formatTestSelector(props.testSelector, 'childrenCount')}
        />
      </AcFlex>
    </AcModalBody>
  );
};

export default memo(OccupancyDiscrepancyModalBody);
