import React, { FC, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as appActions from '@hkm/components/App/domain/actions';
import DayUseBadge from '@hkm/components/shared/DayUseBadge/DayUseBadge';
import EtaEtdGuaranteedBadge from '@hkm/components/shared/EtaEtdGuaranteedBadge/EtaEtdGuaranteedBadge';
import ReservationMovementDetailsItem from '@hkm/components/shared/ReservationMovements/ReservationMovementDetailsItem';
import ReservationStatusBadge from '@hkm/components/shared/ReservationStatusBadge/ReservationStatusBadge';
import GuestReservationDetailsTemplate from '@hkm/components/shared/Templates/Reservation/GuestReservationDetails/GuestReservationDetailsTemplate';
import { ReservationNotesTile } from '@hkm/components/shared/Templates/Reservation/ReservationNotes/ReservationNotesTile';
import { useGroupedReservationNotes } from '@hkm/shared/hooks/useGroupedReservationNotes';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcFormElement,
  AcFormGroup,
} from '@ac/mobile-components/dist/components/form-element';
import {
  AcTile,
  AcTileVariant,
} from '@ac/mobile-components/dist/components/tile';
import { JustifyContent } from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

import './NextUseModalReservationBody.css';

export interface ReservationModalBodyProps {
  roomId: string;
  showGuestNames: boolean;
  reservations: UnifiedReservationDetails[];
}

const NextUseModalReservationBody: FC<ReservationModalBodyProps> = (
  props: ReservationModalBodyProps
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reservationIds = useMemo(() => {
    return props.reservations.map((reservation) => reservation.id) || [];
  }, [props.reservations]);

  const groupedReservationNotes = useGroupedReservationNotes(reservationIds);

  useEffect(() => {
    dispatch(appActions.fetchReservationNotes.trigger(reservationIds));

    // eslint-disable-next-line
  }, [reservationIds]);

  return (
    <>
      {props.reservations.map((reservation) => (
        <React.Fragment key={reservation.id}>
          <AcTile
            variant={AcTileVariant.Borderless}
            title={t('NEXT_USE_MODAL.RESERVATION_DETAILS')}
            icon={IconName.profileDetails}
            className="next-use-modal-reservation-body-tile"
          >
            <AcFormGroup>
              <AcFlex
                className="ac-spacing-top-md"
                justifyContent={JustifyContent.spaceBetween}
              >
                <ac-box sizeSm={8}>
                  <AcFormElement
                    label={t('GLOBAL.RESERVATION_STATUS.TITLE.LONG')}
                  >
                    <ReservationStatusBadge
                      status={reservation.status?.code as ReservationStatus}
                      showLabel={true}
                    />
                  </AcFormElement>
                </ac-box>
                <ac-box sizeSm={8}>
                  {reservation.isDayUse && <DayUseBadge />}
                  {reservation.isTimeGuaranteed && <EtaEtdGuaranteedBadge />}
                </ac-box>
              </AcFlex>

              <ReservationMovementDetailsItem reservation={reservation} />
            </AcFormGroup>
          </AcTile>
          <GuestReservationDetailsTemplate
            disableModals={true}
            roomId={props.roomId}
            reservation={reservation}
            variant={AcTileVariant.Borderless}
            showGuestNames={props.showGuestNames}
            hideReservationStatus={true}
            hideReservationMovement={true}
            className="next-use-modal-reservation-body-tile"
            testSelector="nextUseModal-reservationBody-guestInformationTile"
          />
          {reservation.status?.code === ReservationStatus.DI && (
            <ReservationNotesTile
              key={reservation.id}
              reservationId={reservation.id}
              notesReservationMap={groupedReservationNotes}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default memo(NextUseModalReservationBody);
