import * as housekeepingDashboardActions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import * as maintenanceDashboardActions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import * as actions from '@hkm/components/shared/Templates/Maintenance/shared/domain/actions';
import {
  createMaintenanceUpdateSaga,
  MaintenanceUpdateSagaApi,
} from '@hkm/shared/domain/maintenanceUpdate/maintenanceUpdateSagaFactory';
import { MaintenanceUpdateData } from '@hkm/shared/domain/maintenanceUpdate/models/maintenanceUpdateData';
import { getCustomConfig } from '@hkm/utils/getCustomConfig';
import { fork, put, takeLatest } from 'redux-saga/effects';

import { MobileAttendantApi } from '@ac/library-api/dist/api/v0/housekeeping';
import { HousekeepingRoomsApiV1 } from '@ac/library-api/dist/api/v1/housekeeping';

const attendantMaintenanceUpdateApi: MaintenanceUpdateSagaApi = {
  postUpdateMaintenance: async (
    maintenanceUpdateData: MaintenanceUpdateData
  ): Promise<unknown> => {
    const {
      data: { comment, reasonCode, maintenanceId },
    } = maintenanceUpdateData;

    return await MobileAttendantApi.postUpdateMaintenance({
      pathParams: { roomId: maintenanceUpdateData.roomId },
      data: { comment, reasonCode, maintenanceId },
      customConfig: getCustomConfig({
        version: maintenanceUpdateData.housekeepingRoomVersion,
      }),
    });
  },
};

const housekeepingMaintenanceUpdateApi: MaintenanceUpdateSagaApi = {
  postUpdateMaintenance: async (
    maintenanceUpdateData: MaintenanceUpdateData
  ): Promise<unknown> => {
    return await HousekeepingRoomsApiV1.postUpdateMaintenance({
      pathParams: { roomNumber: maintenanceUpdateData.roomNumber },
      data: maintenanceUpdateData.data,
      customConfig: getCustomConfig({
        version: maintenanceUpdateData.housekeepingRoomVersion,
      }),
    });
  },
};

function* onChange() {
  yield put(housekeepingDashboardActions.pagination.clearPage());
  yield put(maintenanceDashboardActions.pagination.clearPage());
}

export default function* maintenanceUpdateSagas() {
  yield fork(
    createMaintenanceUpdateSaga(
      actions.updateAttendantMaintenanceActionsSet,
      attendantMaintenanceUpdateApi
    )
  );
  yield fork(
    createMaintenanceUpdateSaga(
      actions.updateMaintenanceActionsSet,
      housekeepingMaintenanceUpdateApi
    )
  );
  yield takeLatest(
    actions.updateMaintenanceActionsSet.updateMaintenance.success,
    onChange
  );
  yield takeLatest(
    actions.updateAttendantMaintenanceActionsSet.updateMaintenance.success,
    onChange
  );
}
