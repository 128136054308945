import { HousekeepingStatisticsPermissions } from '@hkm/components/Housekeeping/Statistics/config/housekeepingStatisticsPermissionsConfig';
import { HousekeepingStatisticsState } from '@hkm/components/Housekeeping/Statistics/domain/interfaces';
import { selectPermissionsSet } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import { hasPermissionObject } from '@hkm/shared/permissions/helper/permissionHelper';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectHousekeepingStatisticsState = (
  state: Store
): HousekeepingStatisticsState => state.housekeepingStatistics;

export const selectHousekeepingStatisticsPermissionObject = createSelector(
  selectPermissionsSet,
  (set) => hasPermissionObject(set, HousekeepingStatisticsPermissions)
);
