import { MaintenancePagination } from '@hkm/components/Maintenance/Dashboard/domain/interfaces';
import { Store } from '@hkm/store/interfaces/store';
import { createSelector } from 'reselect';

export const selectMaintenanceDashboardPagination = (
  state: Store
): MaintenancePagination => state.maintenanceDashboard.paginationState;
export const selectMaintenanceDashboardSort = createSelector(
  selectMaintenanceDashboardPagination,
  (state) => state.sort
);
export const selectMaintenanceDashboardFilters = createSelector(
  selectMaintenanceDashboardPagination,
  (state) => state.filters
);
export const selectMaintenanceDashboardIsLoading = createSelector(
  selectMaintenanceDashboardPagination,
  (state) => !!state.isLoading
);
