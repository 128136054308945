import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGuestCountFromUnifiedReservationDetails } from '@hkm/components/Housekeeping/shared/helpers/getGuestCountFromUnifiedReservationDetails';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DoNotMoveInfo from '@hkm/components/shared/DoNotMove/DoNotMoveInfo';
import IncognitoBadge from '@hkm/components/shared/IncognitoBadge/IncognitoBadge';
import ReservationBadges from '@hkm/components/shared/ReservationBadges/ReservationBadges';
import { getReservationBadgesCount } from '@hkm/components/shared/ReservationBadges/utils';
import ReservationMovementDetailsItem from '@hkm/components/shared/ReservationMovements/ReservationMovementDetailsItem';
import GuestDisabilitiesTemplate from '@hkm/components/shared/Templates/Guest/Disabilities/GuestDisabilitiesTemplate';
import ConnectGuestAvatarTemplate from '@hkm/components/shared/Templates/Guest/GuestAvatar/ConnectGuestAvatarTemplate';
import GuestNameTemplate from '@hkm/components/shared/Templates/Guest/GuestNameTemplate';
import GuestNumbersTemplate from '@hkm/components/shared/Templates/Guest/GuestNumbersTemplate';
import GuestPreferencesTemplate from '@hkm/components/shared/Templates/Guest/Preferences/GuestPreferencesTemplate';
import VipOrMemberTemplate from '@hkm/components/shared/Templates/Guest/VipOrMember/VipOrMemberTemplate';
import AccompanyingGuestsDetailsTemplate from '@hkm/components/shared/Templates/Reservation/AccompanyingGuests/AccompanyingGuestsDetailsTemplate';
import BookingNotesAccordion from '@hkm/components/shared/Templates/Reservation/BookingNotesAccordion/BookingNotesAccordion';
import PostChargeButton from '@hkm/components/shared/Templates/Reservation/PostChargeButton/PostChargeButton';
import {
  getVipAndMemberBadges,
  hasReservationAccompanyingGuests,
  hasReservationDisabilities,
  hasReservationPreferences,
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import {
  NotesReservationMap,
  NotesTypeMap,
} from '@hkm/shared/hooks/useGroupedReservationNotes';
import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';
import { doesReservationHaveDoNotMoveFlag } from '@hkm/shared/reservations/hasDoNotMoveFlag';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';

import { ReservationStatus } from '@ac/library-api';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcFormElement,
  AcFormGroup,
} from '@ac/mobile-components/dist/components/form-element';
import {
  AcTile,
  AcTileVariant,
} from '@ac/mobile-components/dist/components/tile';
import { AlignItems, FlexDirection } from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { IconName } from '@ac/web-components';

export interface GuestInformationTileProps extends Styleable, Testable {
  roomId: string;
  disableModals?: boolean;
  variant?: AcTileVariant;
  showGuestNames?: boolean;
  hideReservationStatus?: boolean;
  hideReservationMovement?: boolean;
  reservation: UnifiedReservationDetails;
  groupedReservationNotes?: NotesReservationMap;
}

const GuestReservationDetailsTemplate: FC<GuestInformationTileProps> = ({
  showGuestNames = true,
  ...props
}: GuestInformationTileProps) => {
  const { t } = useTranslation();
  const guest = props.reservation.guest;
  const effectiveValues = useSelector(selectEffectiveValues);
  const guestVipColor = guest?.vipCode?.color;
  const hasPreferences: boolean = hasReservationPreferences(props.reservation);
  const hasDisabilities: boolean = hasReservationDisabilities(guest);
  const hasAccompanyingGuests: boolean = hasReservationAccompanyingGuests(
    props.reservation
  );
  const vipOrMemberBadges: VipOrMemberBadge[] = getVipAndMemberBadges(
    props.reservation
  );
  const hasDoNotMoveFlag = doesReservationHaveDoNotMoveFlag(props.reservation);
  const showBadges = getReservationBadgesCount(props.reservation) > 0;
  const notesTypeMap: NotesTypeMap | undefined =
    props.groupedReservationNotes &&
    props.groupedReservationNotes.get(props.reservation.id);

  return (
    <>
      <AcTile
        variant={props.variant}
        title={t('ROOM_DETAILS.GUEST_INFORMATION')}
        icon={IconName.profileDetails}
        className={props.className}
        style={props.style}
      >
        <AcFormGroup>
          {hasDoNotMoveFlag && <DoNotMoveInfo small={true} />}

          <IncognitoBadge
            testSelector={props.testSelector}
            details={guest?.personalData?.incognitoDetails}
            disableModal={props.disableModals}
          />

          {showGuestNames && guest && (
            <AcFlex alignItems={AlignItems.center}>
              {effectiveValues?.displayGuestAvatar && (
                <ConnectGuestAvatarTemplate
                  className="ac-spacing-right-sm"
                  guest={guest}
                />
              )}
              <AcFormElement
                label={t('ROOM_DETAILS.GUEST')}
                testSelector={formatTestSelector(props.testSelector, 'guest')}
              >
                <GuestNameTemplate
                  vipBadgeColor={guestVipColor}
                  guestPersonalData={guest.personalData}
                  hasDisabilities={!!guest.disabilityStatusCodes?.length}
                />
              </AcFormElement>
            </AcFlex>
          )}

          {hasDisabilities && (
            <AcFormElement
              label={t('ROOM_DETAILS.DISABILITIES')}
              testSelector={formatTestSelector(
                props.testSelector,
                'disabilities'
              )}
            >
              <GuestDisabilitiesTemplate
                disableModal={props.disableModals}
                disabilities={guest?.disabilityStatusCodes}
                testSelector={formatTestSelector(
                  props.testSelector,
                  'disabilitiesValue'
                )}
              />
            </AcFormElement>
          )}

          {!!vipOrMemberBadges.length && (
            <AcFormElement
              label={t('ROOM_DETAILS.VIP_OR_MEMBER')}
              testSelector={formatTestSelector(props.testSelector, 'vip')}
            >
              <VipOrMemberTemplate
                disableModal={props.disableModals}
                vipOrMemberBadges={vipOrMemberBadges}
                testSelector={formatTestSelector(
                  props.testSelector,
                  'vipValue'
                )}
              />
            </AcFormElement>
          )}

          {hasPreferences && (
            <AcFormElement
              label={t('ROOM_DETAILS.PREFERENCES')}
              testSelector={formatTestSelector(
                props.testSelector,
                'preferences'
              )}
            >
              <GuestPreferencesTemplate
                disableModal={props.disableModals}
                preferences={props.reservation.preferences}
                testSelector={formatTestSelector(
                  props.testSelector,
                  'preferencesValue'
                )}
              />
            </AcFormElement>
          )}

          <AcFormElement
            label={t('ROOM_DETAILS.GUEST_COUNT')}
            testSelector={formatTestSelector(props.testSelector, 'guestCount')}
          >
            <GuestNumbersTemplate
              count={getGuestCountFromUnifiedReservationDetails(
                props.reservation
              )}
            />
          </AcFormElement>

          {!props.hideReservationStatus && (
            <AcFlex direction={FlexDirection.row}>
              <ac-box sizeSm={8}>
                <AcFormElement
                  label={t('GLOBAL.RESERVATION_STATUS.TITLE.LONG')}
                  testSelector={formatTestSelector(
                    props.testSelector,
                    'reservationStatus'
                  )}
                >
                  {props.reservation.status?.description}
                </AcFormElement>
              </ac-box>

              {showBadges && (
                <ac-box sizeSm={8}>
                  <ReservationBadges reservation={props.reservation} />
                </ac-box>
              )}
            </AcFlex>
          )}

          {!props.hideReservationMovement && (
            <ReservationMovementDetailsItem
              reservation={props.reservation}
              testSelector={props.testSelector}
            />
          )}

          {hasAccompanyingGuests && (
            <AccompanyingGuestsDetailsTemplate
              disableModals={props.disableModals}
              showGuestNames={showGuestNames}
              accompanyingGuests={props.reservation.accompanyingGuests}
            />
          )}
        </AcFormGroup>

        <PostChargeButton
          roomId={props.roomId}
          reservationId={props.reservation.id}
          reservationStatus={
            props.reservation.status?.code as ReservationStatus
          }
        />
      </AcTile>

      {notesTypeMap && notesTypeMap.size > 0 && (
        <AcTile
          title={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.NOTES')}
          icon={IconName.notes}
          className="ac-spacing-top-sm"
        >
          {Array.from(notesTypeMap.keys()).map(
            (noteType: string, key: number) => {
              const groupedBookingNotesType = notesTypeMap.get(noteType);

              if (!groupedBookingNotesType) {
                return;
              }

              return (
                <BookingNotesAccordion
                  key={key}
                  title={groupedBookingNotesType.description}
                  bookingNotes={groupedBookingNotesType.bookingNotes}
                />
              );
            }
          )}
        </AcTile>
      )}
    </>
  );
};

export default memo(GuestReservationDetailsTemplate);
