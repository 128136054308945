import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';
import { getChipsConfig } from '@hkm/components/TaskManagement/Dashboard/config/chipsConfig';
import { getDateTimeFiltersOptions } from '@hkm/components/TaskManagement/Dashboard/config/dateFiltersConfig';
import { getMultiselectFiltersOptions } from '@hkm/components/TaskManagement/Dashboard/config/multiselectFiltersConfig';
import DashboardFiltersChipsItem from '@hkm/components/TaskManagement/Dashboard/DashboardFiltersChips/DashboardFiltersChipsItem/DashboardFiltersChipsItem';
import {
  getDashboardFilters,
  getDashboardQuickFilter,
  getDashboardSearchString,
} from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { getFiltersDefaultStateWithQuickFilter } from '@hkm/components/TaskManagement/Dashboard/helpers';
import { useTaskDashboardManager } from '@hkm/components/TaskManagement/Dashboard/useTaskDashboardManager';
import { getRelationsState } from '@hkm/components/TaskManagement/relations/domain/selectors';
import { disabledMenuSwipeClass } from '@hkm/features/app/panels/menu/behaviours/useSwipeEvent';

import { AcBadgeList } from '@ac/mobile-components/dist/components/badge';
import {
  AcButton,
  AcButtonSize,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';

import './DashboardFiltersChips.css';

const DashboardFiltersChips: FC = () => {
  const { t } = useTranslation();
  const { updateFiltersAndSearch, updatePaginationPage } =
    useTaskDashboardManager();
  const { shortDate: shortDateFormat, time: timeFormat } = useSelector(
    selectPropertyDateFormats
  );
  const searchValue = useSelector(getDashboardSearchString);
  const filtersState = useSelector(getDashboardFilters);
  const relations = useSelector(getRelationsState);
  const quickFilter = useSelector(getDashboardQuickFilter);

  const onFiltersClear = useCallback(() => {
    const filtersDefaultState = quickFilter
      ? getFiltersDefaultStateWithQuickFilter(quickFilter, filtersState)
      : {};
    updatePaginationPage(1);
    updateFiltersAndSearch(
      { search: undefined, filters: filtersDefaultState },
      false
    );
  }, [filtersState, quickFilter, updateFiltersAndSearch, updatePaginationPage]);

  const chipsConfig = useMemo(
    () =>
      getChipsConfig(
        getDateTimeFiltersOptions(),
        getMultiselectFiltersOptions(),
        filtersState,
        shortDateFormat,
        timeFormat,
        updateFiltersAndSearch,
        quickFilter,
        searchValue,
        relations
      ),
    [
      filtersState,
      searchValue,
      relations,
      shortDateFormat,
      timeFormat,
      quickFilter,
      updateFiltersAndSearch,
    ]
  );

  return (
    <>
      {!!chipsConfig.length && (
        <ConnectedFooter noPadding={true}>
          <div
            className={`advanced-filters-footer-chips ${disabledMenuSwipeClass}`}
          >
            <div className="chips">
              <AcFlex className="chips-scroll">
                <AcButton
                  size={AcButtonSize.Small}
                  className="ac-spacing-left-sm"
                  testSelector="task-dashboard-clear-all-filters"
                  onClick={onFiltersClear}
                >
                  <AcButtonContent text={t('GLOBAL.CLEAR_ALL')} />
                </AcButton>
                <AcBadgeList wrap={false} className="ac-spacing-left-sm">
                  {chipsConfig.map((chips, index) => (
                    <DashboardFiltersChipsItem
                      key={`${chips.fieldDisplayValue}-${index}`}
                      chips={chips}
                    />
                  ))}
                </AcBadgeList>
              </AcFlex>
            </div>
          </div>
        </ConnectedFooter>
      )}
    </>
  );
};

export default memo(DashboardFiltersChips);
