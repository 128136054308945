import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentsEventsProps } from '@hkm/components/shared/Templates/Maintenance/attachmentsEventsProps';
import AttachmentDeleteModal from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/DeleteModal/MaintenanceAttachmentDeleteModal';
import { MaintenanceAttachmentEditChange } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/EditModal/maintenanceAttachmentEditChange';
import AttachmentEditModal from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/EditModal/MaintenanceAttachmentEditModal';
import { AttachmentMangeAccessLevel } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/enum/attachmentMangeAccessLevel';
import { createNewMaintenanceAttachmentDataFromFile } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/factories/maintenanceAttachmentDataFactory';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import { getAttachmentManageAccess } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/utils/getAttachmentManageAccess';
import { acceptedAttachments } from '@hkm/shared/consts/acceptedAttachments';
import { viewFile } from '@hkm/shared/helpers/viewFile';
import classNames from 'classnames';

import {
  AcButtonPattern,
  AcButtonSize,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFileButton } from '@ac/mobile-components/dist/components/file-button';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcIconButton } from '@ac/mobile-components/dist/components/icon-button';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FileInputAccept,
  FileInputCapture,
  MobileColor,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import {
  Changeable,
  Childless,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { IconName } from '@ac/web-components';

import './MaintenanceAttachments.css';

interface AttachmentsProps
  extends Styleable,
    Childless,
    Testable,
    Changeable<MaintenanceAttachmentData[]>,
    AttachmentsEventsProps {
  customerId?: string;
  attachments?: MaintenanceAttachmentData[];
  manageAccessLevel: AttachmentMangeAccessLevel;
}

// tslint:disable:jsx-no-lambda
const MaintenanceAttachments: FC<AttachmentsProps> = (props) => {
  const { t } = useTranslation();
  const [editedAttachment, setEditedAttachment] = useState<
    MaintenanceAttachmentData | undefined
  >();
  const [removingAttachment, setRemovingAttachment] = useState<
    MaintenanceAttachmentData | undefined
  >();
  const [attachments, setAttachments] = useState<MaintenanceAttachmentData[]>(
    props.attachments || []
  );
  const requestedViewId = useRef<string>();

  useEffect(() => {
    const newAttachments = props.attachments || [];

    // Try to fulfill requested file view if new list contains the file we want to see
    if (requestedViewId.current) {
      const foundAttachment = newAttachments.find(
        (attachment) => attachment.id === requestedViewId.current
      );
      if (foundAttachment && foundAttachment.file) {
        viewFile(foundAttachment.file);
        requestedViewId.current = undefined;
      }
    }

    setAttachments(newAttachments);
  }, [props.attachments]);

  const className: string = classNames(
    'maintenance-attachments',
    props.className
  );

  function onFileChange(file: File): void {
    const newAttachment = createNewMaintenanceAttachmentDataFromFile(file);
    setEditedAttachment(newAttachment);
  }

  function onEditModalClose() {
    setEditedAttachment(undefined);
  }

  function onDeleteModalClose() {
    setRemovingAttachment(undefined);
  }

  function emitChange(newAttachments: MaintenanceAttachmentData[]) {
    if (props.onChange) {
      props.onChange(newAttachments);
    }
  }

  function isEditingMetadata(): boolean {
    return !!editedAttachment;
  }

  function isEditingExistingMetadata() {
    return editedAttachment
      ? isEditingMetadata() && attachments.includes(editedAttachment)
      : false;
  }

  function onDelete(attachment: MaintenanceAttachmentData) {
    const index = attachments.indexOf(attachment);
    const newAttachments = attachments
      .slice(0, index)
      .concat(attachments.slice(index + 1));

    if (props.onAttachmentRemove) {
      props.onAttachmentRemove(attachment);
    }
    emitChange(newAttachments);
  }

  function onChangeClick(data: MaintenanceAttachmentData) {
    setEditedAttachment(data);
  }

  function onDeleteClick(data: MaintenanceAttachmentData) {
    setRemovingAttachment(data);
  }

  function onMetadataChange(
    oldData: MaintenanceAttachmentData,
    change: MaintenanceAttachmentEditChange
  ) {
    const newData: MaintenanceAttachmentData = {
      ...oldData,
      fileName: change.name,
      description: change.description,
    };

    const isNewlyCreated: boolean = !isEditingExistingMetadata();
    const newAttachments: MaintenanceAttachmentData[] = isNewlyCreated
      ? [...attachments, newData]
      : attachments.map((data) => (data === oldData ? newData : data));

    if (isNewlyCreated && props.onAttachmentAdd) {
      props.onAttachmentAdd(newData);
    }
    if (!isNewlyCreated && props.onAttachmentUpdate) {
      props.onAttachmentUpdate(newData);
    }
    emitChange(newAttachments);
  }

  function onViewClick(attachment: MaintenanceAttachmentData) {
    if (attachment.file) {
      viewFile(attachment.file);
    } else if (props.onAttachmentFileRequest) {
      props.onAttachmentFileRequest(attachment);
      requestedViewId.current = attachment.id;
    }
  }

  const attachmentTestSelector = formatTestSelector(
    props.testSelector,
    'attachment'
  );

  return (
    <div className={className} style={props.style}>
      {attachments.map((data: MaintenanceAttachmentData) => {
        const hasManageAccess = getAttachmentManageAccess(
          data,
          props.manageAccessLevel,
          props.customerId
        );
        const selector = formatTestSelector(attachmentTestSelector, data.id);

        return (
          <div key={data.id} className="maintenance-attachments-item">
            <AcFlex alignItems={AlignItems.center}>
              <ac-icon
                icon={IconName.notes}
                class="maintenance-attachments-icon ac-spacing-right-sm"
              />
              <AcText
                className="maintenance-attachments-item-name"
                ellipsis={true}
                equalLineHeight={true}
                testSelector={formatTestSelector(selector, 'fileName')}
              >
                {data.fileName}
              </AcText>
              <AcIconButton
                icon={IconName.view}
                className="maintenance-attachments-icon ac-spacing-right-sm"
                onClick={() => onViewClick(data)}
                testSelector={formatTestSelector(selector, 'viewButton')}
              />

              {hasManageAccess && (
                <>
                  <AcIconButton
                    icon={IconName.edit}
                    className="maintenance-attachments-icon ac-spacing-right-sm"
                    onClick={() => onChangeClick(data)}
                    testSelector={formatTestSelector(selector, 'editButton')}
                  />
                  <AcIconButton
                    icon={IconName.delete}
                    className="maintenance-attachments-icon"
                    onClick={() => onDeleteClick(data)}
                    testSelector={formatTestSelector(selector, 'deleteButton')}
                  />
                </>
              )}
            </AcFlex>
            <AcText
              className="maintenance-attachments-item-description ac-spacing-top-xxs"
              size={TextSize.Main2}
              ellipsis={true}
              color={MobileColor.Gray1}
              testSelector={formatTestSelector(selector, 'description')}
            >
              {data.description}
            </AcText>
          </div>
        );
      })}

      {props.manageAccessLevel !== AttachmentMangeAccessLevel.None && (
        <AcFlex>
          <AcFileButton
            testSelector={formatTestSelector(props.testSelector, 'photoButton')}
            pattern={AcButtonPattern.Tertiary}
            capture={FileInputCapture.Environment}
            accept={[FileInputAccept.Image]}
            className="maintenance-attachments-button ac-spacing-right-sm"
            onChange={onFileChange}
            size={AcButtonSize.Small}
          >
            <AcButtonContent
              icon={IconName.image}
              text={t('ATTACHMENTS.TAKE_PHOTO')}
            />
          </AcFileButton>
          <AcFileButton
            testSelector={formatTestSelector(
              props.testSelector,
              'attachButton'
            )}
            pattern={AcButtonPattern.Tertiary}
            accept={acceptedAttachments}
            className="maintenance-attachments-button"
            onChange={onFileChange}
            size={AcButtonSize.Small}
          >
            <AcButtonContent
              icon={IconName.export}
              text={t('ATTACHMENTS.ATTACH_FILE')}
            />
          </AcFileButton>
        </AcFlex>
      )}

      {isEditingMetadata() && editedAttachment && (
        <AttachmentEditModal
          testSelector={formatTestSelector(props.testSelector, 'editModal')}
          onChange={(change) => onMetadataChange(editedAttachment, change)}
          onClose={onEditModalClose}
          attachmentName={editedAttachment.fileName}
          attachmentDescription={editedAttachment.description}
          isEditing={isEditingExistingMetadata()}
        />
      )}
      {!!removingAttachment && (
        <AttachmentDeleteModal
          testSelector={formatTestSelector(props.testSelector, 'deleteModal')}
          onRemove={() => onDelete(removingAttachment)}
          onClose={onDeleteModalClose}
          attachmentName={removingAttachment.fileName}
        />
      )}
    </div>
  );
};

export default memo(MaintenanceAttachments);
