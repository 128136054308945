import React from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from '@hkm/shared/helpers/noop';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { JustifyContent, TextSize } from '@ac/mobile-components/dist/enums';
import { Color, IconName, Size } from '@ac/web-components';

import './AlertBarContent.scss';

interface Props {
  queueRoomsCount: number;
  showLink?: boolean;
  onShow?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const QueueRoomsAlertBar: React.FC<Props> = ({
  queueRoomsCount,
  showLink,
  onShow = noop,
}) => {
  const { t } = useTranslation();

  return (
    <AcFlex
      justifyContent={JustifyContent.spaceBetween}
      className="alert-bar-content"
    >
      <AcText size={TextSize.Main2}>
        <ac-icon
          class="ac-spacing-right-md"
          icon={IconName.queue}
          color={Color.warning}
          size={Size.md}
        />
        {t('ATTENDANT_ASSIGNMENTS.SHARED.ALERT_BAR.QUEUE_ROOMS', {
          count: queueRoomsCount,
        })}
      </AcText>
      {showLink && (
        <a href="#" onClick={onShow} className="link">
          <AcText size={TextSize.Main2}>{t('GLOBAL.SHOW')}</AcText>
        </a>
      )}
    </AcFlex>
  );
};
