import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import {
  selectMaintenanceDashboardFilters,
  selectMaintenanceDashboardPagination,
} from '@hkm/components/Maintenance/Dashboard/domain/selectors';
import MaintenanceDashboardFabContainer from '@hkm/components/Maintenance/Dashboard/Fab/MaintenanceDashboardFabContainer';
import MaintenanceDashboardFooter from '@hkm/components/Maintenance/Dashboard/Footer/MaintenanceDashboardFooter';
import MaintenanceDashboardRoom from '@hkm/components/Maintenance/Dashboard/RoomItem/MaintenanceDashboardRoom';
import MaintenanceDashboardSubHeader from '@hkm/components/Maintenance/Dashboard/SubHeader/MaintenanceDashboardSubHeader';
import * as detailsActions from '@hkm/components/Maintenance/Details/domain/actions';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import PaginatedTileList from '@hkm/components/shared/PaginatedList/PaginatedTileList';
import { HousekeepingNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/HousekeepingNotificationEnvelopeContent';
import { MaintenanceNotificationEnvelopeContent } from '@hkm/services/signalRClient/envelopeContent/MaintenanceNotificationEnvelopeContent';
import { SignalREventType } from '@hkm/services/signalRClient/signalREventType';
import { useNotification } from '@hkm/shared/hooks/useNotification';

import { NotificationEnvelope } from '@ac/library-api/dist/types/entities/signalr/notificationEnvelope';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';

const MaintenanceDashboard: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pagination = useSelector(selectMaintenanceDashboardPagination);
  const filters = useSelector(selectMaintenanceDashboardFilters);
  const bodyScrollRef = useRef<HTMLDivElement>(null);
  const hasFilters = filters && Object.values(filters).some(Boolean);

  const notificationCallback = useCallback(
    (
      data:
        | NotificationEnvelope<MaintenanceNotificationEnvelopeContent>
        | string
    ) => {
      typeof data === 'string'
        ? dispatch(actions.patchChangedMaintenance(data))
        : dispatch(
            actions.patchChangedMaintenance(data.notification?.maintenanceId)
          );
    },
    [dispatch]
  );

  useNotification(
    SignalREventType.RoomMaintenanceChanged,
    notificationCallback
  );

  const onHousekeepingRoomChange = useCallback(
    (data: NotificationEnvelope<HousekeepingNotificationEnvelopeContent>) => {
      const roomChangedId = data.notification.roomId;
      const maintenanceOnDashboard = pagination.page?.results?.find(
        ({ roomNumber }) => roomNumber?.id === roomChangedId
      );

      if (maintenanceOnDashboard) {
        notificationCallback(maintenanceOnDashboard.id);
      }
    },
    [pagination.page?.results, notificationCallback]
  );

  useNotification(
    SignalREventType.HousekeepingRoomChanged,
    onHousekeepingRoomChange
  );

  const onFiltersReset = useCallback(() => {
    dispatch(actions.pagination.setFilters({}));
  }, [dispatch]);

  useLayoutEffect(() => {
    if (pagination.pageId) {
      // Scroll table to top if we fetched a new page
      bodyScrollRef.current?.scrollTo(0, 0);
    }

    // eslint-disable-next-line
  }, [bodyScrollRef.current, pagination.pageId]);

  useEffect(() => {
    dispatch(detailsActions.reset());
    dispatch(actions.pagination.enableAutoRefresh());

    return () => {
      dispatch(actions.pagination.disableAutoRefresh());
    };

    // eslint-disable-next-line
  }, []);

  const onPageRequest = useCallback(
    (pageNumber: number) => {
      dispatch(actions.pagination.fetchPage(pageNumber));
    },
    [dispatch]
  );

  const roomItems: ReactNode[] = useMemo(
    () =>
      (pagination.page?.results || []).map((room) => (
        <MaintenanceDashboardRoom
          room={room}
          testSelector={formatTestSelector(
            'maintenanceDashboard-room',
            room.roomNumber?.code
          )}
          key={room.id}
        />
      )),
    [pagination.page?.results]
  );

  const subheader = useMemo(() => <MaintenanceDashboardSubHeader />, []);
  const fabs = useMemo(() => <MaintenanceDashboardFabContainer />, []);

  return (
    <>
      <ConnectedHeader showHelpButton={true}>
        {t('MAINTENANCE_DASHBOARD.TITLE')}
      </ConnectedHeader>
      <AcBody
        showSpinner={pagination.isLoading}
        scrollRef={bodyScrollRef}
        className="maintenance-dashboard-body"
        subheader={subheader}
        fabs={fabs}
      >
        <PaginatedTileList
          className="maintenance-dashboard-list"
          noContentTitle={t('MAINTENANCE_TABLE.NO_CONTENT_TITLE')}
          isLoading={pagination.isLoading}
          hasFilters={hasFilters}
          onFiltersReset={onFiltersReset}
          onPageRequest={onPageRequest}
          paging={pagination.page?.paging}
        >
          {roomItems}
        </PaginatedTileList>
      </AcBody>
      <MaintenanceDashboardFooter />
    </>
  );
};

export default memo(MaintenanceDashboard);
