import React, { FC, HTMLAttributes, memo, useMemo, useRef } from 'react';
import { createTranslatedNoContentText } from '@hkm/shared/helpers/translatedNoContentTextFactory';
import { createPaginatorProps } from '@hkm/shared/pagination/paginatorPropsFactory';
import classNames from 'classnames';

import { PagingInfo } from '@ac/library-api';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcNoContent } from '@ac/mobile-components/dist/components/no-content';
import { AcNoContentText } from '@ac/mobile-components/dist/components/no-content/acNoContentText';
import { AcPaginator } from '@ac/mobile-components/dist/components/paginator';
import { AcTileGroup } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';

import './PaginatedTileList.css';

interface Props extends HTMLAttributes<HTMLDivElement>, Testable {
  noContentTitle: string;
  isLoading?: boolean;
  hasFilters?: boolean;
  onFiltersReset?: () => void;
  paging?: PagingInfo;
  onPageRequest: (pageNumber: number) => void;
}

const PaginatedTileList: FC<Props> = ({
  children,
  className: classNameProp,
  noContentTitle,
  isLoading,
  hasFilters,
  paging,
  onPageRequest,
  onFiltersReset,
  ...rest
}) => {
  const className = classNames('paginated-tile-list', classNameProp);

  const noContentText: AcNoContentText = useMemo(
    () => createTranslatedNoContentText(noContentTitle),
    [noContentTitle]
  );

  const isEmpty = !!paging && paging.totalCount === 0 && !isLoading;
  const lastNonLoadingEmptyState = useRef<boolean>(isLoading || isEmpty);
  if (!isLoading) {
    lastNonLoadingEmptyState.current = isEmpty;
  }

  return (
    <AcFlex direction={FlexDirection.column} className={className} {...rest}>
      <AcFlex
        className="paginated-tile-list-content"
        direction={FlexDirection.column}
      >
        {!isEmpty ? (
          <AcTileGroup>{children}</AcTileGroup>
        ) : (
          <AcFlex
            className="paginated-tile-list-no-content-container"
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.center}
          >
            <AcNoContent
              text={noContentText}
              isFiltered={hasFilters}
              onFiltersReset={onFiltersReset}
            />
          </AcFlex>
        )}
      </AcFlex>
      {!lastNonLoadingEmptyState.current && paging && (
        <div className="paginated-tile-list-bottom">
          <AcPaginator {...createPaginatorProps(paging, onPageRequest)} />
        </div>
      )}
    </AcFlex>
  );
};

export default memo(PaginatedTileList);
