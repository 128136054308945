import { HousekeepingStatisticsData } from '@hkm/components/Housekeeping/Statistics/domain/interfaces';

import { createAction, getOneAction } from '@ac/library-utils/dist/redux-utils';

export const fetch = getOneAction<undefined, HousekeepingStatisticsData>(
  'housekeepingStatistics'
);
export const flush = createAction('housekeepingStatistics/flush');
export const enableRefresh = createAction(
  'housekeepingStatistics/enableRefresh'
);
export const disableRefresh = createAction(
  'housekeepingStatistics/disableRefresh'
);
