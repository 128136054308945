import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SheetsSummaryAccordion from '@hkm/components/Attendant/SheetSummary/body/SheetSummaryAccordion/SheetsSummaryAccordion';
import { SheetSummaryGuestPreferences } from '@hkm/components/Attendant/SheetSummary/models/SheetSummaryGuestPreferences';
import BadgeWithCount from '@hkm/components/shared/BadgeWithCount/BadgeWithCount';
import classNames from 'classnames';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

export interface GuestPreferencesProps {
  preferences: SheetSummaryGuestPreferences[];
}

export const GuestPreferences: React.FC<GuestPreferencesProps> = (
  props: GuestPreferencesProps
) => {
  const { t } = useTranslation();

  const total = useMemo(() => {
    return props.preferences.reduce(
      (sum, preference) => sum + preference.count,
      0
    );
  }, [props.preferences]);

  const renderSubtitle = useMemo(
    () => (
      <AcText size={TextSize.H1} weight={TextWeight.Semibold}>
        {total}
      </AcText>
    ),
    [total]
  );

  const renderContent = useMemo(
    () => (
      <AcFlex wrapped={true}>
        {props.preferences.map((preference, index) => (
          <BadgeWithCount
            key={index}
            backgroundColor={MobileColor.InformationLight}
            borderColor={MobileColor.InformationDark}
            badgeText={preference.description}
            count={preference.count}
            className={classNames(
              index !== props.preferences.length - 1 && 'ac-spacing-right-sm',
              'ac-spacing-bottom-sm'
            )}
            testSelector="guest-preference-badge"
          />
        ))}
      </AcFlex>
    ),
    [props.preferences]
  );

  return (
    <SheetsSummaryAccordion
      icon={IconName.preferences}
      title={t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.GUEST_PREFERENCES.TITLE')}
      renderSubtitle={renderSubtitle}
      renderContent={renderContent}
      expandDisabled={total === 0}
    />
  );
};
