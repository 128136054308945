import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEnabledRoomStates } from '@hkm//shared/hooks/useEnabledRoomStates';
import { getFieldErrorMessage } from '@hkm//shared/validation/errorProvider';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import CommentModal from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/Comment/CommentModal';
import { MaintenanceUpdateFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/maintenanceFormData';
import { MaintenanceUpdateFieldDisability } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/MaintenanceUpdateFieldDisability';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel,
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { dayjs } from '@hkm/utils/dayjs-extended';

import {
  DictionaryEntity,
  RoomMaintenanceState,
  RoomStatus,
} from '@ac/library-api';
import { AcFieldDate } from '@ac/mobile-components/dist/components/field';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcSelect } from '@ac/mobile-components/dist/components/select';
import { AcSelectValue } from '@ac/mobile-components/dist/components/select/interfaces/AcSelectValue';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { formFieldFactory, FormRenderProps } from '@ac/react-infrastructure';

import './MaintenanceDetailsTileForm.css';

export interface MaintenanceDetailsFormProps {
  readonly?: boolean;
  disableFields?: MaintenanceUpdateFieldDisability;
  formProps: FormRenderProps<MaintenanceUpdateFormData>;
}

const FormField = formFieldFactory<MaintenanceUpdateFormData>();

/* tslint:disable:jsx-no-lambda */
const MaintenanceDetailsForm: FC<MaintenanceDetailsFormProps> = (
  props: MaintenanceDetailsFormProps
) => {
  const { t } = useTranslation();
  const testSelectorPrefix = 'maintenance-details';
  const formats = useSelector(selectPropertyDateFormats);
  const values = props.formProps.values;
  const isStatePending = values.state === RoomMaintenanceState.Pending;
  const isMaintenanceOpen =
    values.state === RoomMaintenanceState.Pending ||
    values.state === RoomMaintenanceState.Active;
  const isEditable = isMaintenanceOpen && !props.readonly;
  const isStartDateEditable = isStatePending && !props.readonly;

  const roomStates: AcSelectValue[] = useEnabledRoomStates().map(
    (state: DictionaryEntity) => ({
      value: state.code ?? '',
      itemLabel: extractDictionaryEntryLabel(state, DictionaryLabelLength.Long),
    })
  );

  function onReturnStatusChange(newStatus: RoomStatus) {
    props.formProps.form.change('returnStatusCode', newStatus);
    props.formProps.handleSubmit();
    props.formProps.form.reset();
  }

  function onCommentChange(newComment: string) {
    props.formProps.form.change('comment', newComment);
    props.formProps.handleSubmit();
    props.formProps.form.reset();
  }

  function saveData() {
    props.formProps.handleSubmit();
  }

  return (
    <>
      <AcFormElement
        label={t('ROOM_DETAILS.COMMENT')}
        required={isEditable}
        testSelector={formatTestSelector(testSelectorPrefix, 'comment')}
      >
        <FormField valuePath="comment">
          {(fieldRenderProps) => (
            <ac-box class="wrap-content">
              <CommentModal
                comment={props.formProps.values.comment}
                roomType={props.formProps.values.roomType ?? ''}
                roomNumber={props.formProps.values.roomNumber ?? ''}
                onSaveModal={onCommentChange}
                validation={getFieldErrorMessage(fieldRenderProps, false)}
                disabled={!isEditable || props.disableFields?.comment}
              />
            </ac-box>
          )}
        </FormField>
      </AcFormElement>

      <AcFlex className="ac-spacing-top-md">
        <ac-box sizeSm={8} class="ac-spacing-right-xs">
          {!isStartDateEditable && (
            <AcFormElement
              label={t('GLOBAL.START_DATE.SHORT')}
              testSelector={formatTestSelector(testSelectorPrefix, 'startDate')}
            >
              {dayjs(values.fromTime).format(formats.shortDate)}
            </AcFormElement>
          )}

          {isStartDateEditable && (
            <FormField valuePath="fromTime">
              {(fieldRenderProps) => (
                <AcFieldDate
                  {...fieldRenderProps.input}
                  required={isEditable}
                  format={formats.shortDate}
                  label={t('GLOBAL.START_DATE.LONG')}
                  validation={getFieldErrorMessage(fieldRenderProps, false)}
                  onBlur={saveData}
                  max={values.toTime}
                  value={props.formProps.values.fromTime}
                  testSelector={`${testSelectorPrefix}-fromTime`}
                  className={'maintenance-details-date-header'}
                />
              )}
            </FormField>
          )}
        </ac-box>
        <ac-box sizeSm={8} class="ac-spacing-right-xs">
          {!isEditable ||
            (props.disableFields?.toTime && (
              <AcFlex direction={FlexDirection.column}>
                <AcText
                  color={MobileColor.Gray1}
                  size={TextSize.Main2}
                  weight={TextWeight.Semibold}
                >
                  {t('GLOBAL.END_DATE.SHORT')}
                </AcText>
                <AcText color={MobileColor.Black} size={TextSize.Main1}>
                  {dayjs(values.toTime).format(formats.shortDate)}
                </AcText>
              </AcFlex>
            ))}

          {isEditable && !props.disableFields?.toTime && (
            <FormField valuePath="toTime">
              {(fieldRenderProps) => (
                <AcFieldDate
                  {...fieldRenderProps.input}
                  required={isEditable}
                  format={formats.shortDate}
                  label={t('GLOBAL.END_DATE.LONG')}
                  validation={getFieldErrorMessage(fieldRenderProps, false)}
                  onBlur={saveData}
                  min={values.fromTime}
                  value={props.formProps.values.toTime}
                  testSelector={`${testSelectorPrefix}-toTime`}
                  className={'maintenance-details-date-header'}
                />
              )}
            </FormField>
          )}
        </ac-box>
      </AcFlex>

      {!isEditable &&
        props.disableFields?.returnStatusCode &&
        values.returnStatusCode && (
          <AcFlex
            direction={FlexDirection.column}
            className="ac-spacing-top-md"
          >
            <AcText
              color={MobileColor.Gray1}
              size={TextSize.Main2}
              weight={TextWeight.Semibold}
            >
              {t('GLOBAL.RETURN_STATUS.LONG')}
            </AcText>
            <RoomStatusBadge
              status={values.returnStatusCode}
              showLabel={true}
            />
          </AcFlex>
        )}

      {isEditable && !props.disableFields?.returnStatusCode && (
        <FormField valuePath="returnStatusCode">
          {(fieldRenderProps) => (
            <AcSelect
              className="ac-spacing-top-md"
              showInputs={true}
              label={t('GLOBAL.RETURN_STATUS.LONG')}
              onChange={onReturnStatusChange}
              itemsList={roomStates}
              selectedItem={fieldRenderProps.input.value as string}
              testSelector={`${testSelectorPrefix}-return-status`}
              itemTemplate={(item) => (
                <RoomStatusBadge
                  status={item.value as RoomStatus}
                  showLabel={true}
                />
              )}
              validation={getFieldErrorMessage(fieldRenderProps, false)}
              required={isEditable}
              disabled={!isEditable}
            />
          )}
        </FormField>
      )}
    </>
  );
};

export default memo(MaintenanceDetailsForm);
