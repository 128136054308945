/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { memo } from 'react';
import { Navbar } from '@hkm/features/app/features/navbar/Navbar';
import { useMicroFrontendInitialization } from '@hkm/features/microFrontends/behaviours/useMicroFrontendInitialization';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';
import classNames from 'classnames';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcSpinnerCover } from '@ac/mobile-components/dist/components/spinner';
import { AlignItems, JustifyContent } from '@ac/mobile-components/dist/enums';
import { Errors, IconName } from '@ac/web-components';

const Page = (): JSX.Element => {
  const { state } = useMicroFrontendsContext();

  useMicroFrontendInitialization();

  return (
    <>
      {!state.loading && <Navbar />}
      <AcFlex
        grow={true}
        justifyContent={JustifyContent.center}
        alignItems={AlignItems.center}
        className="overflow-hidden"
      >
        {state.loading && <AcSpinnerCover />}
        {state.error && (
          <ac-error-page
            errorType={Errors.custom}
            error={{
              icon: IconName.alert,
              header: state.error.message,
              subheader: state.error.description,
            }}
          />
        )}
        <div
          className={classNames(
            'height-percentage-100',
            'width-percentage-100',
            'module-app',
            {
              hidden: state.error,
            }
          )}
        />
      </AcFlex>
    </>
  );
};

export const MicroFrontendContainer = memo(Page);
