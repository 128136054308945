/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useTranslation } from 'react-i18next';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import { logout } from '@hkm/features/app/commands/logout/logout';
import { Flag } from '@hkm/features/app/panels/menu/features/content/features/flag/LanguageFlag';
import { Languages } from '@hkm/features/app/panels/menu/features/content/features/languages/Languages';
import { MenuLink } from '@hkm/features/app/panels/menu/features/content/features/link/Link';
import { Module } from '@hkm/features/app/panels/menu/features/content/features/module/Module';
import { Properties } from '@hkm/features/app/panels/menu/features/content/features/properties/Properties';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';
import { useMenuContext } from '@hkm/features/app/panels/menu/store/context';
import { useActiveUser } from '@hkm/features/user/useActiveUser';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcBody, AcLayout } from '@ac/mobile-components/dist/components/layout';
import { AcSystemUser } from '@ac/mobile-components/dist/components/system-user';
import { Theme } from '@ac/mobile-components/dist/enums';
import { Color, IconName, Size } from '@ac/web-components';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const { segments } = useMenuContext();
  const presenter = useMenuPanelPresenterContext();
  const user = useActiveUser();

  return (
    <AcLayout className="off-canvas-menu">
      <ConnectedHeader
        forceHideMenu={true}
        left={<ac-icon icon={IconName.acProject} size={Size.xlg} />}
        close={
          !presenter.state.inputData?.hideCloseButton
            ? presenter.hide
            : undefined
        }
        showHelpButton={true}
      >
        {t('MENU.WELCOME')}
      </ConnectedHeader>

      <AcBody contentClassName="app-menu" noPadding={true}>
        <div className="top-wrapper">
          {segments.length <= 0 && (
            <div className="active-module-container">
              <div className="module-icon-wrapper">
                <Module icon={IconName.alert} isActive={true} />
              </div>
              <div className="active-module-content-wrapper">
                <div className="active-module-title">
                  {t(
                    'INITIALIZATION_PROCESS.ERRORS.LACK_OF_AVAILABLE_WORK_SPACES'
                  )}
                </div>
              </div>
            </div>
          )}

          {segments.map((segment) => (
            <div className="active-module-container" key={segment.guid}>
              <div className="module-icon-wrapper">
                <Module icon={segment.icon} isActive={true} />
              </div>
              <div className="active-module-content-wrapper">
                <div className="active-module-title">{segment.title}</div>

                <div className="active-module-content">
                  {segment.pages.map((page) => (
                    <MenuLink
                      key={page.guid}
                      text={page.text}
                      link={page.link}
                      testSelector={page.testSelector}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="bottom-wrapper">
          <div className="item-property">
            <div className="item-icon">
              <Flag />
            </div>
            <div className="item-content">
              <Languages />
            </div>
          </div>

          <div className="item-property">
            <div className="item-icon">
              <Module icon={IconName.home} />
            </div>
            <div className="item-content">
              <Properties />
            </div>
          </div>

          <div className="item-user-data">
            <div className="item-icon">
              <AcSystemUser className="ac-spacing-top-xs" theme={Theme.Light}>
                {user.initials}
              </AcSystemUser>
            </div>

            <div className="item-content">
              <div className="user-data">
                <div className="username">{user.userName}</div>
                <div className="email">{user.email}</div>
              </div>
              <div className="item-logout">
                <AcButton
                  pattern={AcButtonPattern.Tertiary}
                  onClick={logout}
                  testSelector="navigation-button-logout"
                >
                  <ac-icon icon={IconName.power} color={Color.primaryDark} />
                </AcButton>
              </div>
            </div>
          </div>
        </div>
      </AcBody>
    </AcLayout>
  );
};
