import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router';
import { getNextRoomId } from '@hkm/components/Attendant/shared/utils/getNextRoomId';
import { AttendantInstructions } from '@hkm/components/Attendant/SheetSummary/body/AttendantInstructions/AttendantInstructions';
import { GuestPreferences } from '@hkm/components/Attendant/SheetSummary/body/GuestPreferences/GuestPreferences';
import { GuestServiceRequest } from '@hkm/components/Attendant/SheetSummary/body/GuestServiceRequest/GuestServiceRequest';
import { GuestServiceStatus } from '@hkm/components/Attendant/SheetSummary/body/GuestServiceStatus/GuestServiceStatus';
import { HousekeepingTasksSummary } from '@hkm/components/Attendant/SheetSummary/body/HousekeepingTasksSummary/HousekeepingTasksSummary';
import { SpecialNeeds } from '@hkm/components/Attendant/SheetSummary/body/SpecialNeeds/SpecialNeeds';
import { VipsAndMemberships } from '@hkm/components/Attendant/SheetSummary/body/VipsAndMemberships/VipsAndMemberships';
import { createSheetSummaryDataFactory } from '@hkm/components/Attendant/SheetSummary/models/factories/sheetSummaryDataFactory';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import { AttendantSheet } from '@hkm/types/attendant/models/AttendantSheet';

import { AcButton } from '@ac/mobile-components/dist/components/button';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  FlexDirection,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';

import { LinenChange } from './LinenChange/LinenChange';

interface AttendantSheetsSummaryBodyProps {
  attendantSheet: AttendantSheet;
  isSheetInProgress: boolean;
}

const AttendantSheetsSummaryBody: React.FC<AttendantSheetsSummaryBodyProps> = (
  props: AttendantSheetsSummaryBodyProps
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const effectiveValues = useSelector(selectEffectiveValues);
  const { activePlannedGuestServices, activeAdHocGuestServices } =
    useGuestServices();
  const isHousekeepingCleaningScheduleEnabled =
    effectiveValues?.enabledHousekeepingSchedule;

  const sheetSummaryData = useMemo(
    () => createSheetSummaryDataFactory(props.attendantSheet, effectiveValues),
    [props.attendantSheet, effectiveValues]
  );

  // get room id that should be shown after start my id
  // if id undefined it means that we should go to the list
  const nextRoomId = getNextRoomId();

  const onStartMyDate = () => {
    if (nextRoomId) {
      navigate(
        generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, {
          roomId: nextRoomId,
        })
      );
    } else {
      navigate(generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_LIST));
    }
  };

  return (
    <ac-box class="sheets-summary" data-test-selector="sheets-summary">
      <AcFlex direction={FlexDirection.column} className="ac-spacing-lg">
        <AcText weight={TextWeight.Semibold} size={TextSize.Main1}>
          {t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.GREETING', {
            name: sheetSummaryData.attendantName,
          })}
        </AcText>
        <AcText>
          {t(
            'ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.HERE_IS_YOUR_TODAY_SHEET_SUMMARY'
          )}
        </AcText>
      </AcFlex>

      <ac-box class="attendant-assignments-summary">
        <AcFlex
          direction={FlexDirection.column}
          className="ac-spacing-left-sm ac-spacing-right-sm"
        >
          <HousekeepingTasksSummary
            housekeepingTasksSummary={sheetSummaryData.housekeepingTasks}
            floorCount={sheetSummaryData.instructions.floorCount}
          />
          <AttendantInstructions
            instructions={props.attendantSheet.instructions}
          />

          {activePlannedGuestServices.length > 0 && (
            <GuestServiceStatus
              guestServiceStatus={sheetSummaryData.guestService}
            />
          )}

          {activeAdHocGuestServices.length > 0 &&
            isHousekeepingCleaningScheduleEnabled && (
              <GuestServiceRequest
                guestServiceStatus={sheetSummaryData.guestService}
              />
            )}

          <LinenChange linenChange={sheetSummaryData.linenChange} />

          <VipsAndMemberships
            memberships={sheetSummaryData.memberships}
            vips={sheetSummaryData.vips}
          />
          <GuestPreferences preferences={sheetSummaryData.guestPreferences} />
          <SpecialNeeds
            needs={sheetSummaryData.specialNeeds}
            className="ac-spacing-bottom-md"
          />

          <AcButton className="sticky-button-test" onClick={onStartMyDate}>
            {!props.isSheetInProgress &&
              t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.START_MY_DAY')}
            {props.isSheetInProgress &&
              t('ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY.CONTINUE_CLEANING')}
          </AcButton>
        </AcFlex>
      </ac-box>
    </ac-box>
  );
};

export default memo(AttendantSheetsSummaryBody);
