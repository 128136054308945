import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router';
import {
  getCleanTypesMap,
  getRoomConditions,
} from '@hkm/components/App/domain/selectors';
import * as actions from '@hkm/components/Attendant/shared/domain/actions';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import { selectUserDetails } from '@hkm/components/Login/domain/selectors';
import {
  selectEffectiveValues,
  selectPropertyDateFormats,
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import TaskProgressTypeBadge from '@hkm/components/shared/AttendantTaskProgressTypeBadge/AttendantTaskProgressTypeBadge';
import CleanTypeDescriptionModal from '@hkm/components/shared/CleanTypeDescriptionModal/CleanTypeDescriptionModal';
import GuestServiceField from '@hkm/components/shared/GuestService/GuestServiceField';
import { GuestServiceModalFormState } from '@hkm/components/shared/GuestService/modal/form/useFormState';
import NextUseTile from '@hkm/components/shared/NextUse/NextUseTile';
import OccupancyDiscrepancyButton from '@hkm/components/shared/OccupancyDiscrepancy/OccupancyDiscrepancyButton';
import ReservationsMovementsInfo from '@hkm/components/shared/ReservationMovements/ReservationsMovementsInfo';
import { RoomConditionsField } from '@hkm/components/shared/RoomConditions/RoomConditionsField';
import RoomStatusBadge from '@hkm/components/shared/RoomStatusBadge/RoomStatusBadge';
import * as maintenanceAttachmentsActions from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/actions';
import { AttachmentMangeAccessLevel } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/enum/attachmentMangeAccessLevel';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import MaintenanceAttachmentsTile from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/MaintenanceAttachmentsTile';
import MaintenanceDetailsTile from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/MaintenanceDetailsTile';
import { MaintenanceUpdateFieldDisability } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/MaintenanceUpdateFieldDisability';
import * as updateMaintenanceActions from '@hkm/components/shared/Templates/Maintenance/shared/domain/actions';
import GuestReservationDetailsTemplate from '@hkm/components/shared/Templates/Reservation/GuestReservationDetails/GuestReservationDetailsTemplate';
import SharedReservationDetailsTemplate from '@hkm/components/shared/Templates/Reservation/SharedReservationDetails/SharedReservationDetailsTemplate';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { isGreenServiceObtainableByRoom } from '@hkm/shared/domain/greenServiceToggle/utils';
import {
  AddMaintenanceAttachmentData,
  RemoveMaintenanceAttachmentData,
  UpdateMaintenanceAttachmentData,
} from '@hkm/shared/domain/maintenanceAttachment/uploadAttachement/models/maintenanceUploadAttachmentData';
import { BadgeColor } from '@hkm/shared/enum/badgeColor';
import { useGroupedReservationNotes } from '@hkm/shared/hooks/useGroupedReservationNotes';
import { GuestCount } from '@hkm/shared/interfaces/guestCount';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { useReservationMovement } from '@hkm/shared/reservations/reservationMovementHooks';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { AttendantSheet } from '@hkm/types/attendant/models/AttendantSheet';
import { createTimeWithTimezone } from '@hkm/utils/dateHelper';
import { getLocalizedString } from '@hkm/utils/getLocalizedString';

import {
  AttendantTaskProgressType,
  RawGenericEntity,
  ReservationStatus,
  RoomMaintenancesAttachmentPathParam,
  RoomStatus,
  UpdateMaintenances,
} from '@ac/library-api';
import { AcBadge } from '@ac/mobile-components/dist/components/badge';
import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcFormElement,
  AcFormGroup,
  AcFormRow,
} from '@ac/mobile-components/dist/components/form-element';
import { AcSwitch } from '@ac/mobile-components/dist/components/switch';
import {
  AcTile,
  AcTileGroup,
} from '@ac/mobile-components/dist/components/tile';
import {
  BadgeTheme,
  FlexDirection,
  JustifyContent,
} from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { IconName } from '@ac/web-components';

export interface AttendantRoomDetailsBodyProps extends Childless {
  room: AttendantRoom;
  selectedSheet: AttendantSheet;
  maintenanceAttachments: MaintenanceAttachmentData[];
}

const AttendantRoomDetailsBody: FC<AttendantRoomDetailsBodyProps> = (
  props: AttendantRoomDetailsBodyProps
) => {
  const prefix = 'attendant-room-details-body';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const canCreateMaintenance = usePermission(
    Permission.HousekeepingAttendantCreateMaintenanceForAssignedRoom
  );

  const isReadonly = !usePermission(...HousekeepingManagePermissionsConfig);

  const customer = useSelector(selectUserDetails);
  const effectiveValues = useSelector(selectEffectiveValues);
  const cleanTypesMapDictionary = useSelector(getCleanTypesMap);

  const isGreenServiceObtainable = isGreenServiceObtainableByRoom(
    props.room,
    (reservation) => reservation.status?.code as ReservationStatus
  );
  const isGreenServiceEnabled = effectiveValues?.greenService;
  const isOccupancyDiscrepancyEnabled = effectiveValues?.occupancyDiscrepancy;
  const { serviceType } = props.room;
  const cleanType = cleanTypesMapDictionary.get(serviceType?.code || '');

  const [isCleanTypeModalVisible, setCleanTypeModalVisible] = useState(false);

  const { moveIn, moveOut } = useReservationMovement(
    props.room,
    (reservation) => reservation.status?.code as ReservationStatus
  );
  const hasReservationMovement = !!moveIn || !!moveOut;

  const hasReservation = !!props.room.currentMainReservation;
  const hasSharedReservation = !!props.room.currentSharedReservations?.length;
  const hasMaintenance = !!props.room.currentMaintenance;

  const isRoomConditionsFunctionalityEnabled =
    effectiveValues?.enableRoomConditions;
  const roomConditions = (useSelector(getRoomConditions) || [])
    .filter(({ isActive }) => isActive)
    .map((state: RawGenericEntity) => ({
      value: state.id,
      itemLabel: `${state.code} - ${getLocalizedString(state.description)}`,
    }));
  const selectedRoomConditions = (props.room.roomConditions || []).map(
    ({ id }) => id
  );

  const onGuestServiceChangeChange = useCallback(
    (guestServiceModalFormState: GuestServiceModalFormState) => {
      dispatch(
        actions.guestService.changeGuestServiceStatus.trigger({
          untilTime: guestServiceModalFormState.serviceDeferredUntil,
          roomId: props.room.id,
          instruction: guestServiceModalFormState.instruction,
          afterTime: guestServiceModalFormState.servicePreferredAfterTime,
          currentStatus: guestServiceModalFormState.initialServiceType,
          newStatus: guestServiceModalFormState.serviceType,
          versionId: props.room.housekeepingRoomVersion || 0,
        })
      );
    },
    [dispatch, props.room.id, props.room.housekeepingRoomVersion]
  );

  // get notes for active reservation and shared reservation then group them by their types
  const groupedReservationNotes = useGroupedReservationNotes(
    props.room.currentReservationsIds || []
  );

  const addMaintenanceButtonHandler = useCallback(() => {
    navigate(
      generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ADD_MAINTENANCE, {
        roomId: props.room.id,
      })
    );
  }, [props.room.id, navigate]);

  const toggleGreenService = useCallback(
    () =>
      dispatch(
        actions.greenService.toggleGreenService.trigger({
          roomId: props.room.id,
          hasGreenService: props.room.greenService,
          roomVersion: props.room.housekeepingRoomVersion || 0,
        })
      ),
    [
      dispatch,
      props.room.id,
      props.room.greenService,
      props.room.housekeepingRoomVersion,
    ]
  );

  const onRoomOccupancySave = useCallback(
    (count: GuestCount) => {
      dispatch(actions.roomOccupancy.setRoomOccupancy.trigger(count));
    },
    [dispatch]
  );

  const onRoomOccupancyDelete = useCallback(() => {
    dispatch(actions.roomOccupancy.removeRoomOccupancy.trigger());
  }, [dispatch]);

  const onMaintenanceUpdate = useCallback(
    (updateMaintenances: UpdateMaintenances) => {
      dispatch(
        updateMaintenanceActions.updateAttendantMaintenanceActionsSet.updateMaintenance.trigger(
          {
            data: updateMaintenances,
            roomId: props.room.unifiedRoomDetails.id,
            roomNumber: props.room.unifiedRoomDetails.roomNumber ?? '',
            housekeepingRoomVersion:
              props.room.unifiedRoomDetails.housekeepingRoomVersion ?? 0,
          }
        )
      );
    },
    [
      dispatch,
      props.room.unifiedRoomDetails.id,
      props.room.unifiedRoomDetails.roomNumber,
      props.room.unifiedRoomDetails.housekeepingRoomVersion,
    ]
  );

  const onMaintenanceAttachmentAdd = useCallback(
    (data: AddMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttendantAttachmentActionsSet.addAttachment.trigger(
          data
        )
      ),
    [dispatch]
  );

  const onMaintenanceAttachmentRemove = useCallback(
    (data: RemoveMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttendantAttachmentActionsSet.removeAttachment.trigger(
          data
        )
      ),
    [dispatch]
  );

  const onMaintenanceAttachmentUpdate = useCallback(
    (data: UpdateMaintenanceAttachmentData) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttendantAttachmentActionsSet.updateAttachment.trigger(
          data
        )
      ),
    [dispatch]
  );

  const onMaintenanceAttachmentRequest = useCallback(
    (data: RoomMaintenancesAttachmentPathParam) =>
      dispatch(
        maintenanceAttachmentsActions.uploadAttendantAttachmentActionsSet.getAttachmentFile.trigger(
          data
        )
      ),
    [dispatch]
  );

  const onCleanTypeClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setCleanTypeModalVisible(true);
    },
    []
  );

  const onNextUseModalClose = useCallback(
    () => setCleanTypeModalVisible(false),
    []
  );

  const onRoomConditionsChange = useCallback(
    (newSelectedConditions: string[]) => {
      const conditionsToRemove = selectedRoomConditions.filter(
        (id) => !newSelectedConditions.includes(id)
      );
      const conditionsToAdd = newSelectedConditions.filter(
        (id) => !selectedRoomConditions.includes(id)
      );

      dispatch(
        actions.roomConditions.changeRoomConditions.trigger({
          roomId: props.room.id,
          roomConditions: {
            add: conditionsToAdd,
            remove: conditionsToRemove,
          },
          housekeepingRoomVersion: props.room.housekeepingRoomVersion || 0,
        })
      );
    },
    [
      dispatch,
      props.room.housekeepingRoomVersion,
      props.room.id,
      selectedRoomConditions,
    ]
  );

  const disableFields: MaintenanceUpdateFieldDisability = {
    returnStatusCode: true,
    toTime: true,
  };

  const desiredTime =
    props.room.queueRoomEntries && props.room.queueRoomEntries[0]?.desiredTime
      ? props.room.queueRoomEntries[0]?.desiredTime
      : undefined;
  const formats = useSelector(selectPropertyDateFormats);
  const formattedDesiredTime =
    desiredTime && createTimeWithTimezone(desiredTime).format(formats.time);

  const isDesiredTimeEnabled = effectiveValues?.enabledReadyBy;

  return (
    <AcTileGroup className={prefix} test-selector={prefix}>
      <AcTile
        icon={IconName.room}
        title={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.ROOM_INFORMATION')}
      >
        <AcFormGroup>
          {hasReservationMovement && <ReservationsMovementsInfo small={true} />}
          <AcFlex>
            <AcFormElement
              label={t('GLOBAL.TASK_PROGRESS_TYPE.TITLE.LONG')}
              testSelector={formatTestSelector(prefix, 'cleaningStatus')}
            >
              <TaskProgressTypeBadge
                status={
                  props.room.activeTask?.progress
                    ?.code as AttendantTaskProgressType
                }
              />
            </AcFormElement>

            {isDesiredTimeEnabled && desiredTime && (
              <AcFormElement
                className="ready-by-time-room-details"
                label={t('ATTENDANT_ASSIGNMENTS.DESIRED_TIME')}
              >
                {formattedDesiredTime}
              </AcFormElement>
            )}
          </AcFlex>
          <AcFormElement
            label={t('GLOBAL.ROOM_TYPE.LONG')}
            testSelector={formatTestSelector(prefix, 'roomType')}
          >
            {t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.ROOM_TYPE_FORMAT', {
              code: props.room.roomType?.code,
              name: props.room.roomType?.description,
            })}
          </AcFormElement>

          <AcFormElement
            label={t('GLOBAL.FRONT_DESK_STATUS.LONG')}
            testSelector={formatTestSelector(prefix, 'frontdesk')}
          >
            {props.room.frontdeskStatus?.description}
          </AcFormElement>

          {isOccupancyDiscrepancyEnabled && (
            <OccupancyDiscrepancyButton
              testSelector={prefix}
              occupancy={props.room.roomOccupancy}
              onSave={onRoomOccupancySave}
              onDelete={onRoomOccupancyDelete}
            />
          )}
        </AcFormGroup>
      </AcTile>

      <AcTile
        title={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.HOUSEKEEPING')}
        icon={IconName.housekeeping}
      >
        <AcFormGroup>
          <AcFormElement
            label={t('GLOBAL.ROOM_STATUS.LONG')}
            testSelector={formatTestSelector(prefix, 'roomStatus')}
          >
            <RoomStatusBadge
              status={props.room.roomStatus?.code as RoomStatus}
              showLabel={true}
            />
          </AcFormElement>

          <AcFormRow>
            <AcFormElement
              label={t('GLOBAL.CLEARING_TYPE.TITLE')}
              testSelector={formatTestSelector(prefix, 'cleaningType')}
            >
              {props.room.serviceType && cleanType ? (
                <>
                  <a href="#" className="link" onClick={onCleanTypeClick}>
                    <AcBadge
                      badgeText={props.room.serviceType.code}
                      borderColor={cleanType.color ?? BadgeColor.Default}
                      backgroundColor={cleanType.color ?? BadgeColor.Default}
                      theme={BadgeTheme.Informational}
                    />
                  </a>
                  <CleanTypeDescriptionModal
                    onClose={onNextUseModalClose}
                    isVisible={isCleanTypeModalVisible}
                    cleanType={cleanType}
                  />
                </>
              ) : (
                t('GLOBAL.NONE')
              )}
            </AcFormElement>
            <AcFormElement
              label={t('GLOBAL.LINEN_CHANGE')}
              testSelector={formatTestSelector(prefix, 'linenChange')}
            >
              {props.room.linenChange ? t('GLOBAL.YES') : t('GLOBAL.NO')}
            </AcFormElement>
          </AcFormRow>

          <GuestServiceField
            testSelector={formatTestSelector(prefix, 'guest-service')}
            onChange={onGuestServiceChangeChange}
            room={props.room}
          />

          {isRoomConditionsFunctionalityEnabled ? (
            <RoomConditionsField
              isReadOnly={isReadonly}
              testSelectorPrefix={prefix}
              roomConditions={roomConditions}
              selectedRoomConditions={props.room.roomConditions}
              onRoomConditionsChange={onRoomConditionsChange}
            />
          ) : (
            <></>
          )}

          {isGreenServiceEnabled && isGreenServiceObtainable && (
            <AcFormElement
              label={t('GLOBAL.GREEN_SERVICE.TITLE')}
              testSelector={formatTestSelector(prefix, 'greenService')}
              flatAndJustify={JustifyContent.spaceBetween}
            >
              <AcSwitch
                onChange={toggleGreenService}
                state={props.room.greenService}
                selected={props.room.greenService}
                testSelector={`${prefix}-greenServiceSwitch`}
                contractedTouchArea={true}
              />
            </AcFormElement>
          )}

          <AcFormElement
            label={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.EFFORT_POINTS')}
            testSelector={formatTestSelector(prefix, 'effortPoints')}
          >
            {props.room.effortPoints}
          </AcFormElement>
        </AcFormGroup>
      </AcTile>

      {hasMaintenance && props.room.currentMaintenance && (
        <>
          <MaintenanceDetailsTile
            disableFields={disableFields}
            onSubmit={onMaintenanceUpdate}
            unifiedRoomDetails={props.room.unifiedRoomDetails}
            maintenanceDetails={props.room.currentMaintenance}
          />
          <MaintenanceAttachmentsTile
            attachments={props.maintenanceAttachments}
            roomId={props.room.id}
            testSelector="maintenanceAttachmentsTile"
            roomVersionId={props.room.housekeepingRoomVersion ?? 0}
            maintenanceId={props.room.currentMaintenance?.id ?? ''}
            onAdd={onMaintenanceAttachmentAdd}
            onUpdate={onMaintenanceAttachmentUpdate}
            onFileRequest={onMaintenanceAttachmentRequest}
            onRemove={onMaintenanceAttachmentRemove}
            customerId={customer?.id}
            manageAccessLevel={AttachmentMangeAccessLevel.Owner}
          />
        </>
      )}

      {hasReservation &&
        !hasSharedReservation &&
        props.room.currentMainReservation && (
          <GuestReservationDetailsTemplate
            roomId={props.room.id}
            reservation={props.room.currentMainReservation}
            className="ac-spacing-top-sm"
            groupedReservationNotes={groupedReservationNotes}
            showGuestNames={props.selectedSheet.showGuestNames}
          />
        )}

      {hasSharedReservation && props.room.currentReservations && (
        <SharedReservationDetailsTemplate
          roomId={props.room.id}
          className="ac-spacing-top-md"
          reservations={props.room.currentReservations}
          groupedReservationNotes={groupedReservationNotes}
          showGuestNames={props.selectedSheet.showGuestNames}
        />
      )}

      {props.selectedSheet.hasInstructions && (
        <AcTile
          title={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.INSTRUCTION')}
          icon={IconName.audit}
          className="ac-spacing-top-sm"
        >
          {props.selectedSheet.instructions}
        </AcTile>
      )}

      <NextUseTile
        groupedReservedKinds={props.room}
        unifiedRoomDetails={props.room.unifiedRoomDetails}
        showGuestNames={props.selectedSheet.showGuestNames}
      />

      {canCreateMaintenance && (
        <AcFlex
          justifyContent={JustifyContent.center}
          direction={FlexDirection.row}
        >
          <AcButton
            onClick={addMaintenanceButtonHandler}
            pattern={AcButtonPattern.Tertiary}
            testSelector={`${prefix}-add-maintenance`}
            fullwidth={true}
          >
            <AcButtonContent
              icon={IconName.plus}
              text={t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.ADD_MAINTENANCE')}
            />
          </AcButton>
        </AcFlex>
      )}
    </AcTileGroup>
  );
};

export default memo(AttendantRoomDetailsBody);
