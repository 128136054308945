import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoomStatesMap } from '@hkm/components/App/domain/selectors';
import { HousekeepingStatisticsData } from '@hkm/components/Housekeeping/Statistics/domain/interfaces';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import StatisticsSection from '@hkm/components/shared/StatisticsSection/StatisticsSection';
import {
  StatisticSectionDataItem,
  StatisticsSectionData,
} from '@hkm/components/shared/StatisticsSection/StatisticsSectionData';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel,
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { GuestServiceStatusType } from '@hkm/shared/enum/guestServiceStatusType';

import { DiscrepancyType, RoomStatus } from '@ac/library-api';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

interface HousekeepingStatisticsBodyProps extends Childless {
  data: HousekeepingStatisticsData;
}

const HousekeepingStatisticsBody: FC<HousekeepingStatisticsBodyProps> = (
  props: HousekeepingStatisticsBodyProps
) => {
  const { t } = useTranslation();
  const roomStates = useSelector(getRoomStatesMap);
  const effectiveValues = useSelector(selectEffectiveValues);

  const roomSummary = useMemo(() => {
    return props.data.roomSummary;
  }, [props.data.roomSummary]);

  const reservationSummary = props.data.reservationSummary;

  const enabledGuestServiceItems = useMemo(() => {
    const servicesValues: StatisticSectionDataItem[] = [];

    if (effectiveValues?.guestServiceStatuses) {
      if (effectiveValues?.guestServiceStatusDoNotDisturbed) {
        servicesValues.push({
          label: t(
            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.DoNotDisturb}`
          ),
          value: roomSummary?.doNotDisturbCount ?? 0,
        });
      }
      if (effectiveValues?.guestServiceStatusServiceRefused) {
        servicesValues.push({
          label: t(
            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceRefused}`
          ),
          value: roomSummary?.serviceRefusedCount ?? 0,
        });
      }
      if (effectiveValues?.guestServiceStatusServiceRequested) {
        servicesValues.push({
          label: t(
            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceRequested}`
          ),
          value: roomSummary?.serviceRequestedCount ?? 0,
        });
      }
      if (effectiveValues?.guestServiceStatusServiceDeferred) {
        servicesValues.push({
          label: t(
            `GLOBAL.GUEST_SERVICE_STATUS.VALUES.${GuestServiceStatusType.ServiceDeferred}`
          ),
          value: roomSummary?.serviceDeferredCount ?? 0,
        });
      }
    }
    if (effectiveValues?.greenService) {
      servicesValues.push({
        label: t('GLOBAL.GREEN_SERVICE.TITLE'),
        value: roomSummary?.greenServiceCount ?? 0,
      });
    }
    if (effectiveValues?.enabledQueueRooms) {
      servicesValues.push({
        label: t('GLOBAL.QUEUE_ROOMS.PENDING_ROOMS'),
        value: roomSummary?.pendingInQueueCount ?? 0,
      });
      servicesValues.push({
        label: t('GLOBAL.QUEUE_ROOMS.READY_ROOMS'),
        value: roomSummary?.readyInQueueCount ?? 0,
      });
    }

    return servicesValues;
  }, [
    t,
    effectiveValues,
    roomSummary?.doNotDisturbCount,
    roomSummary?.serviceRefusedCount,
    roomSummary?.serviceRequestedCount,
    roomSummary?.serviceDeferredCount,
    roomSummary?.greenServiceCount,
    roomSummary?.pendingInQueueCount,
    roomSummary?.readyInQueueCount,
  ]);

  const discrepanciesItems = useMemo(() => {
    const discrepanciesValues: StatisticSectionDataItem[] = [
      {
        label: t(`GLOBAL.DISCREPANCY.${DiscrepancyType.Sleep}`),
        value: roomSummary?.sleepDiscrepancyCount ?? 0,
      },
      {
        label: t(`GLOBAL.DISCREPANCY.${DiscrepancyType.Skip}`),
        value: roomSummary?.skipDiscrepancyCount ?? 0,
      },
    ];

    if (effectiveValues?.occupancyDiscrepancy) {
      discrepanciesValues.push({
        label: t(
          'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.DISCREPANCIES.OCCUPANCY_DISCREPANCY'
        ),
        value: roomSummary?.occupancyDiscrepancyCount ?? 0,
      });
    }

    return discrepanciesValues;
  }, [
    t,
    effectiveValues,
    roomSummary?.sleepDiscrepancyCount,
    roomSummary?.skipDiscrepancyCount,
    roomSummary?.occupancyDiscrepancyCount,
  ]);

  const getRoomStatusTranslation = useCallback(
    (status: RoomStatus) => {
      return extractDictionaryEntryLabel(
        roomStates.get(status),
        DictionaryLabelLength.Long
      );
    },
    [roomStates]
  );

  const housekeeping: StatisticsSectionData | undefined = useMemo(
    () =>
      props.data.roomSummary
        ? {
            id: 'hk',
            title: t('HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSEKEEPING.TITLE'),
            subtitle: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSEKEEPING.SUBTITLE'
            ),
            mainValue: props.data.roomSummary?.totalCount,
            items: [
              {
                label: getRoomStatusTranslation(RoomStatus.CL),
                value: roomSummary?.cleanCount ?? 0,
              },
              {
                label: getRoomStatusTranslation(RoomStatus.DI),
                value: roomSummary?.dirtyCount ?? 0,
              },
              {
                label: getRoomStatusTranslation(RoomStatus.PU),
                value: roomSummary?.pickupCount ?? 0,
              },
              {
                label: getRoomStatusTranslation(RoomStatus.IN),
                value: roomSummary?.inspectedCount ?? 0,
              },
            ],
          }
        : undefined,
    [
      roomSummary?.inspectedCount,
      roomSummary?.pickupCount,
      roomSummary?.dirtyCount,
      roomSummary?.cleanCount,
      props.data.roomSummary,
      getRoomStatusTranslation,
      t,
    ]
  );

  const houseStatus: StatisticsSectionData = useMemo(() => {
    const items: StatisticSectionDataItem[] = [
      {
        label: t('HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.STAYOVERS'),
        value: roomSummary?.stayoverCount ?? 0,
      },
      {
        label: t(
          'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.TOTAL_ARRIVALS'
        ),
        value: roomSummary?.arrivalCount ?? 0,
      },
      {
        label: t(
          'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.ALLOCATED_ROOMS'
        ),
        value: roomSummary?.allocatedCount ?? 0,
      },
      {
        label: t(
          'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.UNALLOCATED_ROOMS'
        ),
        value: roomSummary?.unallocatedCount ?? 0,
      },
    ];

    if (effectiveValues?.enabledDayUse) {
      items.push(
        {
          label: t(
            'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.ALLOCATED_DAY_USE_COUNT'
          ),
          value: reservationSummary.allocatedDayUseCount,
        },
        {
          label: t(
            'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.TODAY_DAY_USE_COUNT'
          ),
          value: reservationSummary.todayDayUseCount,
        }
      );
    }

    if (effectiveValues?.enabledHourlyInventoryManagement) {
      items.push(
        {
          label: t(
            'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.GUARANTEED_EARLY_ARRIVAL'
          ),
          value: roomSummary?.guaranteedTimeArrivalCount ?? 0,
        },
        {
          label: t(
            'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.GUARANTEED_LATE_DEPARTURE'
          ),
          value: roomSummary?.guaranteedTimeDepartureCount ?? 0,
        }
      );
    }

    return {
      id: 'hs',
      title: t('HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.TITLE'),
      subtitle: t('HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.HOUSE_STATUS.SUBTITLE'),
      mainValue: roomSummary?.departureCount ?? 0,
      items,
    };
  }, [
    t,
    roomSummary,
    reservationSummary,
    effectiveValues?.enabledDayUse,
    effectiveValues?.enabledHourlyInventoryManagement,
  ]);

  const guestSpecialRequest: StatisticsSectionData | null = useMemo(
    () =>
      Object.keys(enabledGuestServiceItems).length > 0
        ? {
            id: 'gsr',
            title: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.GUEST_SPECIAL_REQUEST.TITLE'
            ),
            subtitle: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.GUEST_SPECIAL_REQUEST.SUBTITLE'
            ),
            mainValue: enabledGuestServiceItems.reduce(
              (sum: number, item: StatisticSectionDataItem) =>
                (item.value as number) + sum,
              0
            ),
            items: enabledGuestServiceItems,
          }
        : null,
    [t, enabledGuestServiceItems]
  );

  const discrepancies: StatisticsSectionData | undefined = useMemo(
    () =>
      props.data.roomSummary
        ? {
            id: 'dis',
            title: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.DISCREPANCIES.TITLE'
            ),
            subtitle: t(
              'HOUSEKEEPING_ROOM_STATISTICS.WIDGETS.DISCREPANCIES.SUBTITLE'
            ),
            mainValue: discrepanciesItems.reduce(
              (sum: number, item: StatisticSectionDataItem) =>
                (item.value as number) + sum,
              0
            ),
            items: discrepanciesItems,
          }
        : undefined,
    [discrepanciesItems, props.data.roomSummary, t]
  );

  const displayWidgets = [
    housekeeping,
    houseStatus,
    guestSpecialRequest,
    discrepancies,
  ].filter(Boolean) as StatisticsSectionData[];

  return useMemo(
    () => (
      <AcBody>
        {displayWidgets.map((item) => (
          <StatisticsSection data={item} key={item.id} />
        ))}
      </AcBody>
    ),
    [displayWidgets]
  );
};

export default memo(HousekeepingStatisticsBody);
