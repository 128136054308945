import { HousekeepingStatisticsPermissions } from '@hkm/components/Housekeeping/Statistics/config/housekeepingStatisticsPermissionsConfig';
import * as actions from '@hkm/components/Housekeeping/Statistics/domain/actions';
import { HousekeepingStatisticsData } from '@hkm/components/Housekeeping/Statistics/domain/interfaces';
import { selectHousekeepingStatisticsPermissionObject } from '@hkm/components/Housekeeping/Statistics/domain/selectors';
import { PermissionsObject } from '@hkm/shared/permissions/types/permissionsConfig';
import {
  all,
  cancel,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from '@redux-saga/core/effects';
import { Task } from '@redux-saga/types';

import {
  HousekeepingRoomSummaryDto,
  LibraryApiResponse,
  ReservationSummaryDto,
  ReservationsViewsArrivalsSummary,
  ReservationsViewsDeparturesSummary,
  ReservationsViewsOccupancySummary,
} from '@ac/library-api';
import { ViewsApi } from '@ac/library-api/dist/api/v0/booking/reservations';
import { HousekeepingViewsApi } from '@ac/library-api/dist/api/v0/housekeeping';

const refreshCooldown: number = 20 * 1000;

interface SummaryData {
  roomSummary?: LibraryApiResponse<HousekeepingRoomSummaryDto>;
  arrivalsSummary: LibraryApiResponse<ReservationsViewsArrivalsSummary>;
  departuresSummary: LibraryApiResponse<ReservationsViewsDeparturesSummary>;
  occupancySummary: LibraryApiResponse<ReservationsViewsOccupancySummary>;
  reservationSummary: LibraryApiResponse<ReservationSummaryDto>;
}

function* refreshData() {
  // Repeat all the time
  while (true) {
    // Get permissions...
    const permissions: PermissionsObject<
      typeof HousekeepingStatisticsPermissions
    > = yield select(selectHousekeepingStatisticsPermissionObject);

    // Prepare temporary target object and fill it with casted promises
    const target: SummaryData = {
      roomSummary: permissions.housekeepingViewSummary
        ? (HousekeepingViewsApi.getRoomSummary() as unknown as LibraryApiResponse<HousekeepingRoomSummaryDto>)
        : undefined,
      arrivalsSummary:
        ViewsApi.getArrivalsSummary() as unknown as LibraryApiResponse<ReservationsViewsArrivalsSummary>,
      departuresSummary:
        ViewsApi.getDeparturesSummary() as unknown as LibraryApiResponse<ReservationsViewsDeparturesSummary>,
      occupancySummary:
        ViewsApi.getOccupancySummary() as unknown as LibraryApiResponse<ReservationsViewsOccupancySummary>,
      reservationSummary:
        HousekeepingViewsApi.getReservationSummary() as unknown as LibraryApiResponse<ReservationSummaryDto>,
    };

    // Resolve the object
    try {
      const resolved: SummaryData = yield all(target as never);
      const resolvedData: HousekeepingStatisticsData = {
        roomSummary: resolved.roomSummary?.data,
        arrivalsSummary: resolved.arrivalsSummary?.data,
        departuresSummary: resolved.departuresSummary.data,
        occupancySummary: resolved.occupancySummary.data,
        reservationSummary: resolved.reservationSummary.data,
      };
      yield put(actions.fetch.success(resolvedData));
    } catch (e) {
      yield put(actions.fetch.failure(e));
    }

    // Wait for delay or if someone flushed the data
    yield race([take(actions.flush), delay(refreshCooldown)]);
  }
}

function* handleStart() {
  // Clean page at start
  yield put(actions.flush());

  // Initialize the page refresher and restart it with each trigger
  const refreshTask: Task = yield takeLatest(
    actions.fetch.trigger,
    refreshData
  );

  // Fetch first page
  yield put(actions.fetch.trigger());

  // Wait for refresh disable or a failed request ...
  yield race([take(actions.disableRefresh), take(actions.fetch.failure)]);

  // .. and then cancel the refresher task. Now we wait for another start.
  yield cancel(refreshTask);
}

export default function* housekeepingStatisticsSagas() {
  yield takeLatest(actions.enableRefresh, handleStart);
}
