import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DictionaryEntry } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import {
  getFloorsMap,
  getOutOfOrderReasonsMap,
  getRoomCategoriesMap,
  getRoomLocationsMap,
  getRoomStatesMap,
  getRoomTypesMap,
} from '@hkm/components/App/domain/selectors';
import { MaintenanceAdvancedFiltersConfigs } from '@hkm/components/Maintenance/shared/filters/maintenanceAdvancedFiltersConfigs';
import { MaintenanceAdvancedFiltersOrder } from '@hkm/components/Maintenance/shared/filters/maintenanceAdvancedFiltersOrder';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import Chips from '@hkm/components/shared/Chips/Chips';
import { ChipsItem } from '@hkm/components/shared/Chips/ChipsItem';
import { disabledMenuSwipeClass } from '@hkm/features/app/panels/menu/behaviours/useSwipeEvent';
import classNames from 'classnames';

import {
  Changeable,
  Childless,
  Styleable,
} from '@ac/mobile-components/dist/interfaces/componentProps';

interface MaintenanceFiltersChipsProps
  extends Styleable,
    Childless,
    Changeable<MaintenanceFiltersData> {
  filters: MaintenanceFiltersData;
  deleteKeys?: boolean;
  excludeQuickFilters?: boolean;
}

const MaintenanceFiltersChips: FC<MaintenanceFiltersChipsProps> = (
  props: MaintenanceFiltersChipsProps
) => {
  const { t } = useTranslation();
  const roomTypes = useSelector(getRoomTypesMap);
  const roomCategories = useSelector(getRoomCategoriesMap);
  const floors = useSelector(getFloorsMap);
  const locations = useSelector(getRoomLocationsMap);
  const returnStates = useSelector(getRoomStatesMap);
  const reasons = useSelector(getOutOfOrderReasonsMap);

  const filteredOrder = MaintenanceAdvancedFiltersOrder.filter(
    (field) => !props.excludeQuickFilters || field !== 'inoperationStates'
  );

  function hasAnyAdvancedMaintenanceFilters(
    filter: MaintenanceFiltersData
  ): boolean {
    return filteredOrder.some((field) => !!filter[field]);
  }

  const valueToItemMap = useMemo(
    () =>
      new Map<
        keyof MaintenanceFiltersData,
        (value: string) => DictionaryEntry | undefined
      >()
        .set('roomTypes', (value) => roomTypes.get(value))
        .set('roomCategories', (value) => roomCategories.get(value))
        .set('floors', (value) => floors.get(value))
        .set('locations', (value) => locations.get(value))
        .set('returnStates', (value) => returnStates.get(value))
        .set('maintenanceReasons', (value) => reasons.get(value)),
    [roomTypes, roomCategories, floors, locations, returnStates, reasons]
  );

  const callOnChangeWithout = useCallback(
    (fields: Array<keyof MaintenanceFiltersData>) => {
      if (props.onChange) {
        const newFilters = { ...props.filters };
        if (props.deleteKeys) {
          fields.forEach((field) => delete newFilters[field]);
        } else {
          fields.forEach((field) => (newFilters[field] = undefined));
        }
        props.onChange(newFilters);
      }
    },
    [props]
  );

  const onRemove = useCallback(
    (field: keyof MaintenanceFiltersData) => callOnChangeWithout([field]),
    [callOnChangeWithout]
  );

  const onClear = useCallback(
    () => callOnChangeWithout(MaintenanceAdvancedFiltersOrder),
    [callOnChangeWithout]
  );

  if (!hasAnyAdvancedMaintenanceFilters(props.filters)) {
    return null;
  }

  const chips: Array<ChipsItem<keyof MaintenanceFiltersData>> = filteredOrder
    .filter(
      (field) => !props.excludeQuickFilters || field !== 'inoperationStates'
    )
    .map((field) => {
      const config = MaintenanceAdvancedFiltersConfigs.get(field);
      const itemProvider =
        valueToItemMap.get(field) || ((value: string) => value);
      const fieldValue = props.filters[field];
      const values = fieldValue
        ? Array.isArray(fieldValue)
          ? fieldValue
          : [fieldValue]
        : [];
      const formattedItems = values.map((value) => {
        const item = itemProvider(value);

        return item
          ? config?.shortFormatter(item)
          : t('GLOBAL.NOT_FOUND_FALLBACK');
      });

      return formattedItems.length === 0
        ? null
        : {
            label: `${t(config?.labelKey ?? '')}: ${formattedItems.join(', ')}`,
            value: field,
            id: field,
          };
    })
    .filter(Boolean) as Array<ChipsItem<keyof MaintenanceFiltersData>>;

  const className: string = classNames(
    'maintenance-filters-chips',
    disabledMenuSwipeClass,
    props.className
  );

  return (
    <Chips
      className={className}
      style={props.style}
      items={chips}
      onClear={onClear}
      onRemove={onRemove}
    />
  );
};

export default memo(MaintenanceFiltersChips);
