import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getFloors,
  getFrontDeskStates,
  getHousekeepingStates,
  getRoomCategories,
  getRoomConditions,
  getRoomLocations,
  getRoomTypes,
  getSections,
} from '@hkm/components/App/domain/selectors';
import * as dashboardActions from '@hkm/components/Housekeeping/Dashboard/domain/actions';
import { selectHousekeepingDashboardFilters } from '@hkm/components/Housekeeping/Dashboard/domain/selectors';
import { HousekeepingAdvancedFiltersConfigs } from '@hkm/components/Housekeeping/shared/filters/housekeepingAdvancedFiltersConfigs';
import { HousekeepingAdvancedFiltersOrder } from '@hkm/components/Housekeeping/shared/filters/housekeepingAdvancedFiltersOrder';
import HousekeepingFiltersChips from '@hkm/components/Housekeeping/shared/HousekeepingFiltersChips/HousekeepingFiltersChips';
import { HousekeepingFiltersData } from '@hkm/components/Housekeeping/shared/models/housekeepingFiltersData';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import AdvancedFilters from '@hkm/components/shared/AdvancedFilters/AdvancedFilters';
import { AdvancedFiltersCheckboxData } from '@hkm/components/shared/AdvancedFilters/Checkboxes/advancedFiltersCheckboxData';
import AdvancedFiltersCheckboxes from '@hkm/components/shared/AdvancedFilters/Checkboxes/AdvancedFiltersCheckboxes';
import roomTypesFilter from '@hkm/components/shared/AdvancedFilters/filters/roomTypesFilter';
import AdvancedFiltersMultiSelects from '@hkm/components/shared/AdvancedFilters/Multiselects/AdvancedFiltersMultiSelects';
import { AdvancedFiltersMultiSelectConfig } from '@hkm/components/shared/AdvancedFilters/Multiselects/advancedFiltersMultiSelectsConfig';
import AdvancedFiltersNumberRange from '@hkm/components/shared/AdvancedFilters/NumberRange/AdvancedFiltersNumberRange';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { HousekeepingInoperationStates } from '@hkm/shared/consts/inoperationStates/housekeepingInoperationStates';
import { useGuestServices } from '@hkm/shared/hooks/useGuestServices';
import {
  HkmValidator,
  ValidationStatuses,
} from '@hkm/shared/interfaces/validationStatuses';
import { Validator } from '@hkm/shared/validation/validator';
import { isValidNumberRange } from '@hkm/shared/validation/validators';

import { HousekeepingInoperationStatus } from '@ac/library-api';
import { AcFieldText } from '@ac/mobile-components/dist/components/field';
import {
  AcFormElement,
  AcFormGroup,
} from '@ac/mobile-components/dist/components/form-element';
import { AcSwitch } from '@ac/mobile-components/dist/components/switch';
import { JustifyContent } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { formFieldFactory, ValidationSchema } from '@ac/react-infrastructure';

interface Statuses extends ValidationStatuses {
  roomRanges?: HkmValidator;
}

const Schema: ValidationSchema<Statuses> = {
  roomRanges: isValidNumberRange,
};

// tslint:disable-next-line:no-empty-interface
export type HousekeepingAdvancedFiltersProps = Childless;

const HousekeepingAdvancedFilters: FC<
  HousekeepingAdvancedFiltersProps
> = () => {
  const testSelectorPrefix = 'housekeepingAdvancedFilters';

  const { t } = useTranslation();
  const FormField = useMemo(
    () => formFieldFactory<HousekeepingFiltersData>(),
    []
  );
  const validator = useMemo<Validator<HousekeepingFiltersData, Statuses>>(
    () => new Validator(Schema),
    []
  );
  const initialFilters = useSelector(selectHousekeepingDashboardFilters) ?? {};
  const effectiveValues = useSelector(selectEffectiveValues);
  const roomTypes = useSelector(getRoomTypes);
  const roomCategories = useSelector(getRoomCategories);
  const floors = useSelector(getFloors);
  const sections = useSelector(getSections);
  const locations = useSelector(getRoomLocations);
  const housekeepingStates = useSelector(getHousekeepingStates);
  const frontDeskStates = useSelector(getFrontDeskStates);
  const roomConditions = useSelector(getRoomConditions);
  const { activeGuestServices } = useGuestServices();

  const multiSelectConfigs: Array<
    AdvancedFiltersMultiSelectConfig<HousekeepingFiltersData>
  > = useMemo(() => {
    const localRoomTypesFilter: AdvancedFiltersMultiSelectConfig<HousekeepingFiltersData>['filter'] =
      (formProps, list) =>
        roomTypesFilter({
          list,
          roomCategories: roomCategories ?? [],
          roomTypes: roomTypes ?? [],
          selectedRoomCategoryCodes: formProps.values.roomCategories,
        });

    const filteredFilters = [
      !effectiveValues?.enableRoomConditions ? 'roomConditions' : undefined,
    ].filter(Boolean);

    const labelLess: Array<
      Omit<AdvancedFiltersMultiSelectConfig<HousekeepingFiltersData>, 'label'>
    > = [
      { items: roomTypes, field: 'roomTypes', filter: localRoomTypesFilter },
      { items: roomCategories, field: 'roomCategories' },
      { items: floors, field: 'floors' },
      { items: sections, field: 'sections' },
      { items: locations, field: 'locations' },
      { items: housekeepingStates, field: 'housekeepingStates' },
      { items: frontDeskStates, field: 'frontDeskStates' },
      { items: activeGuestServices, field: 'guestServiceStatuses' },
      { items: roomConditions, field: 'roomConditions' },
    ].filter(Boolean) as Array<
      Omit<AdvancedFiltersMultiSelectConfig<HousekeepingFiltersData>, 'label'>
    >;

    return labelLess
      .map((config) => ({
        ...config,
        label: t(
          HousekeepingAdvancedFiltersConfigs.get(config.field)?.labelKey ?? ''
        ),
      }))
      .filter(({ field }) => !filteredFilters.includes(field))
      .sort(
        (a, b) =>
          HousekeepingAdvancedFiltersOrder.indexOf(a.field) -
          HousekeepingAdvancedFiltersOrder.indexOf(b.field)
      );
  }, [
    roomTypes,
    roomCategories,
    floors,
    sections,
    locations,
    housekeepingStates,
    frontDeskStates,
    roomConditions,
    activeGuestServices,
    effectiveValues?.enableRoomConditions,
    t,
  ]);

  const inoperationsCheckboxData: Array<
    AdvancedFiltersCheckboxData<HousekeepingInoperationStatus>
  > = HousekeepingInoperationStates.map((state) => ({
    value: state,
    label:
      HousekeepingAdvancedFiltersConfigs.get(
        'inoperationStates'
      )?.shortFormatter(state) ?? '',
  }));

  return (
    <AdvancedFilters
      testSelectorPrefix={testSelectorPrefix}
      backPath={APP_ROUTES.HOUSEKEEPING.DASHBOARD}
      dashboardPaginationActions={dashboardActions.pagination}
      validator={validator}
      initialFilters={initialFilters}
      /* tslint:disable-next-line:jsx-no-lambda */
      chips={(values, onChange) => (
        <HousekeepingFiltersChips filters={values} onChange={onChange} />
      )}
      /* tslint:disable-next-line:jsx-no-lambda */
      body={(formProps) => (
        <AcFormGroup>
          <FormField valuePath="query">
            {(fieldRenderProps) => (
              <AcFieldText
                {...fieldRenderProps.input}
                placeholder={t('GLOBAL.FILL')}
                label={t(
                  HousekeepingAdvancedFiltersConfigs.get('query')?.labelKey ??
                    ''
                )}
                testSelector={formatTestSelector(testSelectorPrefix, 'query')}
              />
            )}
          </FormField>

          <AdvancedFiltersNumberRange<HousekeepingFiltersData>
            field="roomRanges"
            testSelectorPrefix={testSelectorPrefix}
            formProps={formProps}
            label={t(
              HousekeepingAdvancedFiltersConfigs.get('roomRanges')?.labelKey ??
                ''
            )}
          />
          <AdvancedFiltersMultiSelects<HousekeepingFiltersData>
            formProps={formProps}
            testSelectorPrefix={testSelectorPrefix}
            configs={multiSelectConfigs}
          />
          <AdvancedFiltersCheckboxes<HousekeepingFiltersData>
            formProps={formProps}
            data={inoperationsCheckboxData}
            label={t(
              HousekeepingAdvancedFiltersConfigs.get('inoperationStates')
                ?.labelKey ?? ''
            )}
            testSelectorPrefix={testSelectorPrefix}
            field="inoperationStates"
          />
          {effectiveValues?.greenService && (
            <FormField valuePath="greenService">
              {(fieldRenderProps) => (
                <AcFormElement
                  label={t(
                    HousekeepingAdvancedFiltersConfigs.get('greenService')
                      ?.labelKey ?? ''
                  )}
                  testSelector={formatTestSelector(
                    testSelectorPrefix,
                    'greenService'
                  )}
                  flatAndJustify={JustifyContent.spaceBetween}
                >
                  <AcSwitch
                    {...fieldRenderProps.input}
                    state={!!fieldRenderProps.input.value}
                    selected={!!fieldRenderProps.input.value}
                    contractedTouchArea={true}
                    testSelector={formatTestSelector(
                      testSelectorPrefix,
                      'greenServiceSwitch'
                    )}
                  />
                </AcFormElement>
              )}
            </FormField>
          )}
        </AcFormGroup>
      )}
    />
  );
};

export default memo(HousekeepingAdvancedFilters);
