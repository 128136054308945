import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import NextUseModal from '@hkm/components/shared/NextUse/NextUseModal/NextUseModal';
import ReservationBadges from '@hkm/components/shared/ReservationBadges/ReservationBadges';
import { getReservationBadgesCount } from '@hkm/components/shared/ReservationBadges/utils';
import { GroupedReservedKinds } from '@hkm/shared/reservedKind/groupedReservedKinds';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';
import { UnifiedReservationDetails } from '@hkm/types/reservation/models/UnifiedReservationDetails';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';
import { dayjs } from '@hkm/utils/dayjs-extended';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import {
  FlexDirection,
  JustifyContent,
  TextSize,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

export interface NextUseTileProps {
  showGuestNames?: boolean;
  unifiedRoomDetails: UnifiedRoomDetails;
  groupedReservedKinds: GroupedReservedKinds;
}

const NextUseTile: FC<NextUseTileProps> = ({
  showGuestNames = true,
  ...props
}: NextUseTileProps) => {
  const { t } = useTranslation();
  const [isNextUseModalVisible, setNextUseModalVisible] = useState(false);

  const formats = useSelector(selectPropertyDateFormats);

  const reservationsToCheck = useMemo(
    () =>
      props.groupedReservedKinds.currentReservedKind ===
      ReservedKind.Reservation
        ? props.groupedReservedKinds.nextReservations
        : props.groupedReservedKinds.futureActiveReservations,
    [props.groupedReservedKinds]
  );

  const earliestReservation = useMemo(() => {
    return (reservationsToCheck || [])
      .concat()
      .sort((a: UnifiedReservationDetails, b: UnifiedReservationDetails) =>
        dayjs(a.eta || a.arrivalDate).isAfter(
          (b.eta ?? '') || (b.arrivalDate ?? '')
        )
          ? 1
          : -1
      )
      .shift();
  }, [reservationsToCheck]);

  const formatArrivalTime = useCallback(
    (reservation?: UnifiedReservationDetails) => {
      const displayDate = reservation?.eta || reservation?.arrivalDate;
      const hasDefinedTime = !!reservation?.eta;

      return dayjs(displayDate).format(
        hasDefinedTime ? formats.shortDateTime : formats.shortDate
      );
    },
    [formats]
  );

  const showBadges = earliestReservation
    ? getReservationBadgesCount(earliestReservation) > 0
    : false;

  const onNextUseClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setNextUseModalVisible(true);
    },
    []
  );

  const onNextUseModalClose = useCallback(() => {
    setNextUseModalVisible(false);
  }, []);

  return (
    <>
      {props.groupedReservedKinds.nextReservedKind !== ReservedKind.None && (
        <AcTile
          icon={IconName.roomAvailability}
          title={t('NEXT_USE_MODAL.LABEL')}
        >
          <AcFlex
            direction={FlexDirection.row}
            justifyContent={JustifyContent.spaceBetween}
          >
            <a href="#" onClick={onNextUseClick} className="link">
              <AcText size={TextSize.Main1}>
                {props.groupedReservedKinds.nextReservedKind ===
                ReservedKind.Reservation
                  ? formatArrivalTime(earliestReservation)
                  : dayjs(
                      props.groupedReservedKinds.nextMaintenance?.fromTime
                    ).format(formats.shortDate)}
              </AcText>
            </a>
            {showBadges && earliestReservation && (
              <AcFlex>
                <ReservationBadges reservation={earliestReservation} />
              </AcFlex>
            )}
          </AcFlex>
          <NextUseModal
            onClose={onNextUseModalClose}
            isVisible={isNextUseModalVisible}
            showGuestNames={!!showGuestNames}
            unifiedRoomDetails={props.unifiedRoomDetails}
            groupedReservedKinds={props.groupedReservedKinds}
          />
        </AcTile>
      )}
    </>
  );
};

export default memo(NextUseTile);
