import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionFormData } from '@hkm/components/Posting/PostingView/Body/PostingDetailsBody';
import APP_CONSTANTS from '@hkm/constants/app.constants';

import { TransactionCodeReadModel } from '@ac/library-api';
import { AcDivider } from '@ac/mobile-components/dist/components/divider';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import {
  AlignItems,
  JustifyContent,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { FormRenderProps } from '@ac/react-infrastructure';
import { IconName } from '@ac/web-components';

import './PostingSummary.css';

export interface PostingSummaryProps extends Testable {
  formProps: FormRenderProps<TransactionFormData>;
  transactionCodesMap: Map<string, TransactionCodeReadModel>;
}

const PostingSummary: FC<PostingSummaryProps> = (
  props: PostingSummaryProps
) => {
  const { t } = useTranslation();
  const prefix = 'posting-view-summary-item';
  const { values } = props.formProps;

  const summaryTransactionCodes = useMemo(
    () =>
      Array.from(props.transactionCodesMap.values()).filter(
        (transactionCode: TransactionCodeReadModel) =>
          // eslint-disable-next-line no-prototype-builtins
          values.hasOwnProperty(transactionCode.id) &&
          values[transactionCode.id] > 0
      ),
    [props.transactionCodesMap, values]
  );

  const totalItems = useMemo(
    () =>
      Object.values(values).reduce((value: number, sum: number) => {
        return sum + value;
      }, 0),
    [values]
  );

  return (
    <AcTile
      icon={IconName.billing}
      className="posting-view-summary"
      title={t('POSTING.POSTING_DETAILS.POSTING_SUMMARY')}
    >
      {!!totalItems && (
        <>
          {summaryTransactionCodes.map(
            (transactionCode: TransactionCodeReadModel) => (
              <AcFlex
                key={transactionCode.id}
                alignItems={AlignItems.center}
                justifyContent={JustifyContent.spaceBetween}
                className="ac-spacing-bottom-sm"
              >
                <AcFlex
                  justifyContent={JustifyContent.flexStart}
                  alignItems={AlignItems.center}
                  className={`${prefix}-name`}
                >
                  <AcText
                    size={TextSize.Main2}
                    ellipsis={true}
                    testSelector={`${props.testSelector}-name`}
                  >
                    {transactionCode.getSubCodeDescription(
                      APP_CONSTANTS.DATA_LANGUAGE
                    )}
                  </AcText>
                </AcFlex>

                <AcFlex
                  justifyContent={JustifyContent.flexEnd}
                  alignItems={AlignItems.center}
                  className={`${prefix}-count`}
                >
                  <AcText
                    size={TextSize.Main2}
                    weight={TextWeight.Semibold}
                    testSelector={`${props.testSelector}-value`}
                  >
                    {t('POSTING.POSTING_DETAILS.SUMMARY_ITEM', {
                      count: values[transactionCode.id],
                    })}
                  </AcText>
                </AcFlex>
              </AcFlex>
            )
          )}

          <AcDivider className="ac-spacing-bottom-sm ac-spacing-top-sm" />

          <AcFlex
            alignItems={AlignItems.center}
            justifyContent={JustifyContent.spaceBetween}
          >
            <AcText size={TextSize.Main2}>
              {t('POSTING.POSTING_DETAILS.TOTAL')}
            </AcText>
            <AcText
              size={TextSize.Main2}
              weight={TextWeight.Semibold}
              testSelector={`${props.testSelector}-total-value`}
            >
              {t('POSTING.POSTING_DETAILS.SUMMARY_ITEM', {
                count: totalItems,
              })}
            </AcText>
          </AcFlex>
        </>
      )}

      {!totalItems && (
        <AcFlex justifyContent={JustifyContent.center}>
          <AcText
            size={TextSize.Main2}
            testSelector={`${props.testSelector}-no-items`}
          >
            {t('POSTING.POSTING_DETAILS.SUMMARY_NO_ITEMS')}
          </AcText>
        </AcFlex>
      )}
    </AcTile>
  );
};

export default memo(PostingSummary);
