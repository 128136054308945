import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceManagePermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceManagePermissionsConfig';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';

import {
  AcFab,
  AcFabChild,
  AcFabContainer,
} from '@ac/mobile-components/dist/components/fab';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { IconName } from '@ac/web-components';

const MaintenanceDashboardFabContainer: FC = () => {
  const { t } = useTranslation();
  const testSelector = 'maintenanceDashboardFabContainer-menuFab';
  const canEditMaintenance = usePermission(
    ...MaintenanceManagePermissionsConfig
  );

  return (
    <AcFabContainer>
      <AcFab
        icon={IconName.actionMenu}
        expandedIcon={IconName.cancel}
        id="menu"
        testSelector={testSelector}
      >
        <AcFabChild
          icon={IconName.filter}
          text={t('ADVANCED_FILTERS.TITLE')}
          testSelector={formatTestSelector(testSelector, 'advancedFilters')}
          link={APP_ROUTES.MAINTENANCE.FILTERS}
        />
        {canEditMaintenance && (
          <AcFabChild
            icon={IconName.plus}
            text={t('MAINTENANCE_CREATE.TITLE.ADD')}
            testSelector={formatTestSelector(testSelector, 'addNew')}
            link={APP_ROUTES.MAINTENANCE.ADD}
          />
        )}
        <AcFabChild
          icon={IconName.projection}
          text={t('MAINTENANCE_STATISTICS.TITLE')}
          testSelector={formatTestSelector(testSelector, 'statistics')}
          link={APP_ROUTES.MAINTENANCE.STATISTICS}
        />
      </AcFab>
    </AcFabContainer>
  );
};

export default memo(MaintenanceDashboardFabContainer);
