import React, { FC, memo } from 'react';
import { getStringByteLength } from '@hkm/utils/getStringByteLength';

import {
  getInitials,
  HousekeepingRoomReservationGuestPersonalDataDetailsDto,
  IdCodeDescriptionColor,
} from '@ac/library-api';
import { AcAvatar } from '@ac/mobile-components/dist/components/avatar';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import {
  Clickable,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { Color, IconName } from '@ac/web-components';

interface GuestAvatarTemplateProps extends Clickable, Testable, Styleable {
  hasDisabilities: boolean;
  guestPersonalData?: HousekeepingRoomReservationGuestPersonalDataDetailsDto;
  guestVipCode?: IdCodeDescriptionColor;
  getAvatar?: Promise<string>;
}

const GuestAvatarTemplate: FC<GuestAvatarTemplateProps> = (
  props: GuestAvatarTemplateProps
) => {
  const { firstName, lastName } = props.guestPersonalData?.name ?? {
    firstName: '',
    lastName: '',
  };
  const initials = getInitials(firstName ?? '', lastName ?? '');
  const hasTwoBytesCharacters = getStringByteLength(initials) > 2;

  const vipColor = props.guestVipCode ? props.guestVipCode.color : undefined;

  return (
    <AcAvatar
      onClick={props.onClick}
      getImage={props.getAvatar}
      contractedTouchArea={true}
      testSelector={formatTestSelector(props.testSelector, 'guestAvatar')}
      indicator={
        vipColor && (
          <AcIndicator backgroundColor={vipColor} icon={IconName.vip} />
        )
      }
      className={props.className}
    >
      {props.hasDisabilities && (
        <ac-icon icon={IconName.disability} color={Color.alert} />
      )}
      {!props.hasDisabilities &&
        (hasTwoBytesCharacters ? (
          <ac-icon icon={IconName.profile} color={Color.black} />
        ) : (
          initials
        ))}
    </AcAvatar>
  );
};

export default memo(GuestAvatarTemplate);
