import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Comment from '@hkm/components/TaskManagement/TaskPreview/Comments/CommentsState/Comment/Comment';
import { SortOptionsCode } from '@hkm/components/TaskManagement/TaskPreview/Comments/domain/enums';
import useCommentsState from '@hkm/components/TaskManagement/TaskPreview/Comments/hooks/useCommentsState';
import { TaskPreviewDetails } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import {
  AcSelect,
  AcSelectPattern,
} from '@ac/mobile-components/dist/components/select';
import { AcSelectValue } from '@ac/mobile-components/dist/components/select/interfaces/AcSelectValue';
import { AcSwitch } from '@ac/mobile-components/dist/components/switch';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';

interface ComponentProps {
  task: TaskPreviewDetails;
}

const CommentsState: FC<ComponentProps> = (props: ComponentProps) => {
  const { t } = useTranslation();
  const {
    showOnlyMyComments,
    toggleShowOnlyMyComments,
    onSortChange,
    sortBy,
    comments,
  } = useCommentsState(props.task);

  const commentsList = useMemo(
    () =>
      comments.map((comment) => (
        <Comment key={comment.creationTime} comment={comment} />
      )),
    [comments]
  );

  const sortOptions: AcSelectValue[] = [
    {
      value: SortOptionsCode.newest,
      itemLabel: t('TASK_MANAGEMENT.TASK_PREVIEW.COMMENTS.NEWEST_FIRST'),
    },
    {
      value: SortOptionsCode.oldest,
      itemLabel: t('TASK_MANAGEMENT.TASK_PREVIEW.COMMENTS.OLDEST_FIRST'),
    },
  ];

  return (
    <AcFlex direction={FlexDirection.column}>
      <AcFlex
        justifyContent={JustifyContent.spaceBetween}
        alignItems={AlignItems.center}
      >
        <AcText weight={TextWeight.Semibold} size={TextSize.Main2}>
          {t('TASK_MANAGEMENT.TASK_PREVIEW.COMMENTS.SHOW_ONLY_MY_COMMENTS')}
        </AcText>
        <AcSwitch
          state={showOnlyMyComments}
          selected={showOnlyMyComments}
          testSelector="task-show-only-my-comments"
          onChange={toggleShowOnlyMyComments}
        />
      </AcFlex>
      <AcSelect
        showInputs={true}
        className="ac-spacing-bottom-md"
        label={t('TASK_MANAGEMENT.TASK_PREVIEW.COMMENTS.SORT_BY')}
        selectedItem={sortBy}
        itemsList={sortOptions}
        pattern={AcSelectPattern.Dropdown}
        testSelector="task-sort-comments"
        onChange={onSortChange}
      />
      {commentsList}
    </AcFlex>
  );
};

export default CommentsState;
