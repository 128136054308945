import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { getPostingHousekeepingReservations } from '@hkm/components/Posting/shared/utils/getPostingReservation';
import ReservationStatusBadge from '@hkm/components/shared/ReservationStatusBadge/ReservationStatusBadge';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { HousekeepingRoom } from '@hkm/types/housekeeping/models/HousekeepingRoom';
import classNames from 'classnames';

import { ReservationStatus } from '@ac/library-api';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import { AlignItems, JustifyContent } from '@ac/mobile-components/dist/enums';
import {
  Selectable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { Color, IconName } from '@ac/web-components';

import './PostingDashboardRoomItem.css';

interface PostingDashboardRoomProps extends Testable, Selectable {
  room: HousekeepingRoom;
}

const PostingDashboardRoomItem: FC<PostingDashboardRoomProps> = ({
  room,
  testSelector,
}) => {
  const { t } = useTranslation();
  const shellClassNames = classNames('posting-dashboard-room');

  const itemPath = useMemo(() => {
    const reservations = getPostingHousekeepingReservations(
      room.currentReservations
    );

    if (reservations.length > 1) {
      return generatePath(APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.RESERVATIONS, {
        roomId: room.id,
      });
    }
    if (reservations.length > 0) {
      return generatePath(APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.POSTING_VIEW, {
        roomId: room.id,
        reservationId: reservations[0].id,
      });
    }

    return APP_ROUTES.MOBILE_POSTING.HOUSEKEEPING.DASHBOARD;
  }, [room]);

  return (
    <AcTileShell className={shellClassNames} testSelector={testSelector}>
      <Link to={itemPath} className="unstyled-link posting-dashboard-room-link">
        <div className="posting-dashboard-room-content">
          <AcFlex alignItems={AlignItems.center}>
            <AcFlex
              justifyContent={JustifyContent.flexStart}
              className="posting-dashboard-room-number"
            >
              <AcFormElement
                smallLabel={true}
                label={t('GLOBAL.ROOM_NUMBER.SHORT')}
                testSelector={formatTestSelector(testSelector, 'roomNumber')}
                ellipsis={true}
              >
                {room.roomNumber?.code}
              </AcFormElement>
            </AcFlex>

            {room.currentMainReservation?.status && (
              <AcFlex
                justifyContent={JustifyContent.center}
                className="posting-dashboard-room-reservation"
              >
                <ReservationStatusBadge
                  status={
                    room.currentMainReservation?.status
                      ?.code as ReservationStatus
                  }
                />
              </AcFlex>
            )}

            <AcFlex
              justifyContent={JustifyContent.center}
              className="posting-dashboard-room-housekeeping"
            >
              <AcFormElement
                smallLabel={true}
                label={t('GLOBAL.HOUSEKEEPING_STATUS.SHORT')}
                testSelector={formatTestSelector(
                  testSelector,
                  'housekeepingStatus'
                )}
              >
                {room.housekeepingStatus?.code}
              </AcFormElement>
            </AcFlex>

            <AcFlex
              justifyContent={JustifyContent.center}
              className="posting-dashboard-room-posting"
            >
              <ac-icon
                icon={IconName.postingCharge}
                color={Color.primaryDark}
                class="ac-spacing-right-md"
              />
              {t('POSTING.DASHBOARD.POSTING')}
            </AcFlex>
          </AcFlex>
        </div>
      </Link>
    </AcTileShell>
  );
};

export default memo(PostingDashboardRoomItem);
