import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import Accordion from '@hkm/components/TaskManagement/TaskPreview/Comments/CommentsState/Comment/Accordion/Accordion';
import { getTaskRelatedUsers } from '@hkm/components/TaskManagement/TaskPreview/domain/selectors';
import DateTimeHelpers from '@hkm/components/TaskManagement/utils/dateTime';
import APP_CONSTANTS from '@hkm/constants/app.constants';

import { TaskEntry } from '@ac/library-api';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import {
  MobileColor,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';

interface ComponentProps {
  comment: TaskEntry;
}

const Comment: FC<ComponentProps> = (props: ComponentProps) => {
  const { comment } = props;
  const taskRelatedUsers = useSelector(getTaskRelatedUsers);
  const { shortDate } = useSelector(selectPropertyDateFormats);

  const author = useMemo(() => {
    const user = (taskRelatedUsers || []).find(
      ({ id }) => id === comment.userId
    );

    return (
      <AcText
        size={TextSize.Main2}
        color={MobileColor.Gray1}
        weight={TextWeight.Semibold}
        className="ac-spacing-right-sm"
        testSelector="comment-creator"
      >
        {`${user?.personalData?.firstName} ${user?.personalData?.lastName}`}
      </AcText>
    );
  }, [comment.userId, taskRelatedUsers]);

  const date = useMemo(() => {
    const { dueDate } = DateTimeHelpers.separateTimeDate(comment.creationTime);
    const formattedDate = dueDate
      ? DateTimeHelpers.formatDateForField(
          dueDate,
          APP_CONSTANTS.API_DATE_FORMAT
        )
      : '';
    const dateForField =
      dueDate && DateTimeHelpers.formatDateForField(formattedDate, shortDate);

    return dateForField ? (
      <AcText size={TextSize.Main2} testSelector="comment-creation-date">
        {dateForField}
      </AcText>
    ) : (
      ''
    );
  }, [comment.creationTime, shortDate]);

  const meta = useMemo(
    () => (
      <AcFlex>
        {author}
        {date}
      </AcFlex>
    ),
    [author, date]
  );

  return <Accordion header={meta} content={comment.comment ?? ''} />;
};

export default memo(Comment);
