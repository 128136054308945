import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { RoomMaintenanceState } from '@ac/library-api';
import {
  AcDotBadge,
  AcDotBadgeProps,
} from '@ac/mobile-components/dist/components/dot-badge';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { MobileColor, TextSize } from '@ac/mobile-components/dist/enums';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';

interface Props extends AcDotBadgeProps {
  state: RoomMaintenanceState;
}

const colorMap = new Map<RoomMaintenanceState, MobileColor>()
  .set(RoomMaintenanceState.Active, MobileColor.Good)
  .set(RoomMaintenanceState.Pending, MobileColor.Warning)
  .set(RoomMaintenanceState.Canceled, MobileColor.Gray3)
  .set(RoomMaintenanceState.Released, MobileColor.Gray3);

const MaintenanceStateDotBadge: FC<Props> = ({
  testSelector: testSelectorProp,
  state,
  children,
  ...rest
}) => {
  const color = colorMap.get(state) || MobileColor.Gray3;
  const { t } = useTranslation();

  return (
    <AcDotBadge
      {...rest}
      color={color}
      {...getTestSelectorProp(testSelectorProp, 'maintenanceStateBadge')}
    >
      <AcText size={TextSize.Main2}>
        {t(`GLOBAL.MAINTENANCE_STATE.${state}`)}
      </AcText>
    </AcDotBadge>
  );
};

export default memo(MaintenanceStateDotBadge);
