import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getFloors,
  getOutOfOrderReasons,
  getRoomCategories,
  getRoomLocations,
  getRoomTypes,
} from '@hkm/components/App/domain/selectors';
import * as dashboardActions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import { selectMaintenanceDashboardFilters } from '@hkm/components/Maintenance/Dashboard/domain/selectors';
import { MaintenanceAdvancedFiltersConfigs } from '@hkm/components/Maintenance/shared/filters/maintenanceAdvancedFiltersConfigs';
import { MaintenanceAdvancedFiltersOrder } from '@hkm/components/Maintenance/shared/filters/maintenanceAdvancedFiltersOrder';
import MaintenanceFiltersChips from '@hkm/components/Maintenance/shared/MaintenanceFiltersChips/MaintenanceFiltersChips';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import AdvancedFilters from '@hkm/components/shared/AdvancedFilters/AdvancedFilters';
import { AdvancedFiltersCheckboxData } from '@hkm/components/shared/AdvancedFilters/Checkboxes/advancedFiltersCheckboxData';
import AdvancedFiltersCheckboxes from '@hkm/components/shared/AdvancedFilters/Checkboxes/AdvancedFiltersCheckboxes';
import AdvancedFiltersDate from '@hkm/components/shared/AdvancedFilters/Date/AdvancedFiltersDate';
import roomTypesFilter from '@hkm/components/shared/AdvancedFilters/filters/roomTypesFilter';
import AdvancedFiltersMultiSelects from '@hkm/components/shared/AdvancedFilters/Multiselects/AdvancedFiltersMultiSelects';
import { AdvancedFiltersMultiSelectConfig } from '@hkm/components/shared/AdvancedFilters/Multiselects/advancedFiltersMultiSelectsConfig';
import AdvancedFiltersNumberRange from '@hkm/components/shared/AdvancedFilters/NumberRange/AdvancedFiltersNumberRange';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { HousekeepingInoperationStates } from '@hkm/shared/consts/inoperationStates/housekeepingInoperationStates';
import {
  EnabledRoomStatesPurpose,
  useEnabledRoomStates,
} from '@hkm/shared/hooks/useEnabledRoomStates';
import {
  HkmValidator,
  ValidationStatuses,
} from '@hkm/shared/interfaces/validationStatuses';
import { Validator } from '@hkm/shared/validation/validator';
import { isValidNumberRange } from '@hkm/shared/validation/validators';

import {
  HousekeepingInoperationStatus,
  RoomMaintenanceState,
} from '@ac/library-api';
import { AcFormGroup } from '@ac/mobile-components/dist/components/form-element';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';
import { ValidationSchema } from '@ac/react-infrastructure';

interface Statuses extends ValidationStatuses {
  roomRanges?: HkmValidator;
}

const Schema: ValidationSchema<Statuses> = {
  roomRanges: isValidNumberRange,
};

// tslint:disable-next-line:no-empty-interface
export type MaintenanceAdvancedFiltersProps = Childless;

const MaintenanceAdvancedFilters: FC<MaintenanceAdvancedFiltersProps> = () => {
  const { t } = useTranslation();
  const testSelectorPrefix = 'maintenanceAdvancedFilters';

  const validator = useMemo<Validator<MaintenanceFiltersData, Statuses>>(
    () => new Validator(Schema),
    []
  );
  const initialFilters = useSelector(selectMaintenanceDashboardFilters) ?? {};
  const roomTypes = useSelector(getRoomTypes);
  const roomCategories = useSelector(getRoomCategories);
  const floors = useSelector(getFloors);
  const locations = useSelector(getRoomLocations);
  const returnStates = useEnabledRoomStates(EnabledRoomStatesPurpose.DISPLAY);
  const reasons = useSelector(getOutOfOrderReasons);

  const multiSelectConfigs: Array<
    AdvancedFiltersMultiSelectConfig<MaintenanceFiltersData>
  > = useMemo(() => {
    const localRoomTypesFilter: AdvancedFiltersMultiSelectConfig<MaintenanceFiltersData>['filter'] =
      (formProps, list) =>
        roomTypesFilter({
          list,
          roomCategories: roomCategories ?? [],
          roomTypes: roomTypes ?? [],
          selectedRoomCategoryCodes: formProps.values.roomCategories,
        });

    const labelLess: Array<
      Omit<AdvancedFiltersMultiSelectConfig<MaintenanceFiltersData>, 'label'>
    > = [
      {
        items: roomTypes ?? [],
        field: 'roomTypes',
        filter: localRoomTypesFilter,
      },
      { items: roomCategories ?? [], field: 'roomCategories' },
      { items: floors ?? [], field: 'floors' },
      { items: locations ?? [], field: 'locations' },
      { items: returnStates, field: 'returnStates' },
      { items: reasons ?? [], field: 'maintenanceReasons' },
    ];

    return labelLess
      .map((config) => ({
        ...config,
        label: t(
          MaintenanceAdvancedFiltersConfigs.get(config.field)?.labelKey ?? ''
        ),
      }))
      .sort(
        (a, b) =>
          MaintenanceAdvancedFiltersOrder.indexOf(a.field) -
          MaintenanceAdvancedFiltersOrder.indexOf(b.field)
      );
  }, [t, roomTypes, roomCategories, floors, locations, returnStates, reasons]);

  const inoperationsCheckboxData: Array<
    AdvancedFiltersCheckboxData<HousekeepingInoperationStatus>
  > = HousekeepingInoperationStates.map((state) => ({
    value: state,
    label:
      MaintenanceAdvancedFiltersConfigs.get(
        'inoperationStates'
      )?.shortFormatter(state) ?? '',
  }));

  const maintenanceStatesCheckboxData: Array<
    AdvancedFiltersCheckboxData<RoomMaintenanceState>
  > = [RoomMaintenanceState.Released, RoomMaintenanceState.Canceled].map(
    (state) => ({
      value: state,
      label:
        MaintenanceAdvancedFiltersConfigs.get(
          'extraMaintenanceStates'
        )?.shortFormatter(state) ?? '',
    })
  );

  return (
    <AdvancedFilters
      testSelectorPrefix={testSelectorPrefix}
      backPath={APP_ROUTES.MAINTENANCE.DASHBOARD}
      dashboardPaginationActions={dashboardActions.pagination}
      validator={validator}
      initialFilters={initialFilters}
      /* tslint:disable-next-line:jsx-no-lambda */
      chips={(values, onChange) => (
        <MaintenanceFiltersChips filters={values} onChange={onChange} />
      )}
      /* tslint:disable-next-line:jsx-no-lambda */
      body={(formProps) => (
        <AcFormGroup>
          <AdvancedFiltersNumberRange<MaintenanceFiltersData>
            field="roomRanges"
            testSelectorPrefix={testSelectorPrefix}
            formProps={formProps}
            label={t(
              MaintenanceAdvancedFiltersConfigs.get('roomRanges')?.labelKey ??
                ''
            )}
          />
          <AdvancedFiltersMultiSelects<MaintenanceFiltersData>
            formProps={formProps}
            testSelectorPrefix={testSelectorPrefix}
            configs={multiSelectConfigs}
          />
          <AdvancedFiltersDate<MaintenanceFiltersData>
            formProps={formProps}
            field="validOn"
            label={t(
              MaintenanceAdvancedFiltersConfigs.get('validOn')?.labelKey ?? ''
            )}
            testSelectorPrefix={testSelectorPrefix}
          />
          <AdvancedFiltersDate<MaintenanceFiltersData>
            formProps={formProps}
            field="startsOn"
            label={t(
              MaintenanceAdvancedFiltersConfigs.get('startsOn')?.labelKey ?? ''
            )}
            testSelectorPrefix={testSelectorPrefix}
          />
          <AdvancedFiltersDate<MaintenanceFiltersData>
            formProps={formProps}
            field="endsOn"
            label={t(
              MaintenanceAdvancedFiltersConfigs.get('endsOn')?.labelKey ?? ''
            )}
            testSelectorPrefix={testSelectorPrefix}
          />
          <AdvancedFiltersCheckboxes<MaintenanceFiltersData>
            formProps={formProps}
            data={inoperationsCheckboxData}
            label={t(
              MaintenanceAdvancedFiltersConfigs.get('inoperationStates')
                ?.labelKey ?? ''
            )}
            testSelectorPrefix={testSelectorPrefix}
            field="inoperationStates"
          />
          <AdvancedFiltersCheckboxes<MaintenanceFiltersData>
            formProps={formProps}
            data={maintenanceStatesCheckboxData}
            label={t(
              MaintenanceAdvancedFiltersConfigs.get('extraMaintenanceStates')
                ?.labelKey ?? ''
            )}
            testSelectorPrefix={testSelectorPrefix}
            field="extraMaintenanceStates"
          />
        </AcFormGroup>
      )}
    />
  );
};

export default memo(MaintenanceAdvancedFilters);
