import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GuestDisabilitiesTemplate from '@hkm/components/shared/Templates/Guest/Disabilities/GuestDisabilitiesTemplate';
import GuestNameTemplate from '@hkm/components/shared/Templates/Guest/GuestNameTemplate';
import VipOrMemberTemplate from '@hkm/components/shared/Templates/Guest/VipOrMember/VipOrMemberTemplate';
import { MaintenanceFormValidationConflictData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/Validation/maintenanceFormValidationConflictData';
import {
  getVipAndMemberBadges,
  hasReservationDisabilities,
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import { VipOrMemberBadge } from '@hkm/shared/interfaces/vipOrMemberBadge';
import classNames from 'classnames';

import {
  AcButton,
  AcButtonPattern,
} from '@ac/mobile-components/dist/components/button';
import { AcButtonContent } from '@ac/mobile-components/dist/components/button-content';
import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcFormElement } from '@ac/mobile-components/dist/components/form-element';
import { JustifyContent } from '@ac/mobile-components/dist/enums';
import {
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { IconName } from '@ac/web-components';

interface Props extends Styleable, Testable {
  conflict: MaintenanceFormValidationConflictData;
}

const MaintenanceFormValidationConflict: FC<Props> = (props) => {
  const { t } = useTranslation();
  const className = classNames(
    'maintenance-form-validation-conflict',
    props.className
  );

  const onGoToDetailsClick = useCallback(
    () => window.open(props.conflict.link, '_blank'),
    [props.conflict.link]
  );

  const reservation = props.conflict.reservation;

  const guest = reservation && reservation.guest;
  const hasDisabilities: boolean = hasReservationDisabilities(guest);
  const vipOrMemberBadges: VipOrMemberBadge[] =
    getVipAndMemberBadges(reservation);

  return (
    <div className={className} style={props.style}>
      <AcFlex>
        <ac-box sizeSm={8}>
          <AcFormElement
            label={t('GLOBAL.ROOM_NUMBER.LONG')}
            testSelector={formatTestSelector(props.testSelector, 'roomNumber')}
          >
            {props.conflict.roomNumber}
          </AcFormElement>
        </ac-box>
        <ac-box sizeSm={8}>
          <AcFormElement
            label={t('GLOBAL.ROOM_TYPE.LONG')}
            testSelector={formatTestSelector(props.testSelector, 'roomType')}
          >
            {props.conflict.roomType}
          </AcFormElement>
        </ac-box>
      </AcFlex>

      <AcFlex className="ac-spacing-top-sm">
        <ac-box sizeSm={8}>
          <AcFormElement
            label={t('MAINTENANCE_CREATE.VALIDATION.MODAL.REASON')}
            testSelector={formatTestSelector(props.testSelector, 'reason')}
          >
            {props.conflict.reason}
          </AcFormElement>
        </ac-box>
        <ac-box sizeSm={8}>
          <AcFormElement
            label={t('GLOBAL.STATUS')}
            testSelector={formatTestSelector(props.testSelector, 'status')}
          >
            {props.conflict.status}
          </AcFormElement>
        </ac-box>
      </AcFlex>

      <AcFlex className="ac-spacing-top-sm">
        <ac-box sizeSm={8}>
          <AcFormElement
            label={t('GLOBAL.START_DATE.SHORT')}
            testSelector={formatTestSelector(props.testSelector, 'start')}
          >
            {props.conflict.start}
          </AcFormElement>
        </ac-box>
        <ac-box sizeSm={8}>
          <AcFormElement
            label={t('GLOBAL.END_DATE.SHORT')}
            testSelector={formatTestSelector(props.testSelector, 'end')}
          >
            {props.conflict.end}
          </AcFormElement>
        </ac-box>
      </AcFlex>

      {guest && (
        <AcFormElement
          className="ac-spacing-top-sm"
          label={t('ROOM_DETAILS.GUEST')}
          testSelector={formatTestSelector(props.testSelector, 'guest')}
        >
          <GuestNameTemplate guestPersonalData={guest.personalData} />
        </AcFormElement>
      )}

      {hasDisabilities && (
        <AcFormElement
          className="ac-spacing-top-sm"
          label={t('ROOM_DETAILS.DISABILITIES')}
          testSelector={formatTestSelector(props.testSelector, 'disabilities')}
        >
          <GuestDisabilitiesTemplate
            disableModal={true}
            disabilities={guest?.disabilityStatusCodes}
            testSelector={formatTestSelector(
              props.testSelector,
              'disabilitiesValue'
            )}
          />
        </AcFormElement>
      )}

      {!!vipOrMemberBadges.length && (
        <AcFormElement
          className="ac-spacing-top-sm"
          label={t('ROOM_DETAILS.VIP_OR_MEMBER')}
          testSelector={formatTestSelector(props.testSelector, 'vip')}
        >
          <VipOrMemberTemplate
            disableModal={true}
            vipOrMemberBadges={vipOrMemberBadges}
            testSelector={formatTestSelector(props.testSelector, 'vipValue')}
          />
        </AcFormElement>
      )}

      {!!props.conflict.link && (
        <AcFlex justifyContent={JustifyContent.center}>
          <AcButton
            className="ac-spacing-top-md"
            testSelector={formatTestSelector(
              props.testSelector,
              'goToDetailsButton'
            )}
            pattern={AcButtonPattern.Tertiary}
            contractedTouchArea={true}
            onClick={onGoToDetailsClick}
          >
            <AcButtonContent
              icon={IconName.openInNewTab}
              text={t('MAINTENANCE_CREATE.VALIDATION.MODAL.GO_TO_DETAILS')}
            />
          </AcButton>
        </AcFlex>
      )}
    </div>
  );
};

export default memo(MaintenanceFormValidationConflict);
