import { DictionaryEntry } from '@hkm/components/App/domain/interfaces/DictionariesStore';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import {
  DictionaryLabelLength,
  extractDictionaryEntryLabel,
} from '@hkm/shared/dictionaries/dictionaryItemsLabelExtractor';
import { getStore } from '@hkm/store/configureStore';
import { dayjs } from '@hkm/utils/dayjs-extended';
import i18n from 'i18next';

export interface MaintenanceAdvancedFiltersConfig {
  labelKey: string;
  longFormatter: (entry: DictionaryEntry | string) => string;
  shortFormatter: (entry: DictionaryEntry | string) => string;
}

const fullFormatter = (entry: DictionaryEntry) =>
  extractDictionaryEntryLabel(entry, DictionaryLabelLength.Full);
const shortFormatter = (entry: DictionaryEntry) =>
  extractDictionaryEntryLabel(entry, DictionaryLabelLength.Short);
const dateFormatter = (date: string) => {
  const format: string = selectPropertyDateFormats(getStore()).shortDate;

  return dayjs(date).format(format);
};

export const MaintenanceAdvancedFiltersConfigs = new Map<
  keyof MaintenanceFiltersData,
  MaintenanceAdvancedFiltersConfig
>()
  .set('roomRanges', {
    labelKey: 'GLOBAL.ROOM_NUMBER.LONG',
    longFormatter: (value: string) => value,
    shortFormatter: (value: string) => value,
  })
  .set('roomTypes', {
    labelKey: 'GLOBAL.ROOM_TYPE.LONG',
    longFormatter: fullFormatter,
    shortFormatter,
  })
  .set('roomCategories', {
    labelKey: 'GLOBAL.ROOM_CATEGORY.LONG',
    longFormatter: fullFormatter,
    shortFormatter,
  })
  .set('floors', {
    labelKey: 'GLOBAL.FLOOR.LONG',
    longFormatter: fullFormatter,
    shortFormatter,
  })
  .set('locations', {
    labelKey: 'GLOBAL.LOCATION.LONG',
    longFormatter: fullFormatter,
    shortFormatter,
  })
  .set('returnStates', {
    labelKey: 'GLOBAL.RETURN_STATUS.LONG',
    longFormatter: fullFormatter,
    shortFormatter,
  })
  .set('inoperationStates', {
    labelKey: 'GLOBAL.STATUS',
    longFormatter: (status: string) =>
      i18n.t(`GLOBAL.MAINTENANCE_STATUS.SHORT.${status}`),
    shortFormatter: (status: string) =>
      i18n.t(`GLOBAL.MAINTENANCE_STATUS.SHORT.${status}`),
  })
  .set('maintenanceReasons', {
    labelKey: 'MAINTENANCE_FILTERS.REASON',
    longFormatter: fullFormatter,
    shortFormatter,
  })
  .set('extraMaintenanceStates', {
    labelKey: 'MAINTENANCE_FILTERS.INCLUDE',
    longFormatter: (status: string) =>
      i18n.t(`GLOBAL.MAINTENANCE_STATE.${status}`),
    shortFormatter: (status: string) =>
      i18n.t(`GLOBAL.MAINTENANCE_STATE.${status}`),
  })
  .set('startsOn', {
    labelKey: 'MAINTENANCE_FILTERS.STARTS_ON',
    longFormatter: dateFormatter,
    shortFormatter: dateFormatter,
  })
  .set('endsOn', {
    labelKey: 'MAINTENANCE_FILTERS.ENDS_ON',
    longFormatter: dateFormatter,
    shortFormatter: dateFormatter,
  })
  .set('validOn', {
    labelKey: 'MAINTENANCE_FILTERS.VALID_ON',
    longFormatter: dateFormatter,
    shortFormatter: dateFormatter,
  });
