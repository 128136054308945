import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CommentsState from '@hkm/components/TaskManagement/TaskPreview/Comments/CommentsState/CommentsState';
import { TaskPreviewDetails } from '@hkm/components/TaskManagement/TaskPreview/domain/interfaces';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import {
  FlexDirection,
  TextSize,
  TextWeight,
} from '@ac/mobile-components/dist/enums';
import { IconName } from '@ac/web-components';

import './Comments.css';

interface ComponentProps {
  task: TaskPreviewDetails;
}

const Comments: FC<ComponentProps> = (props: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <AcTileShell className="task-comments-wrapper">
      <AcFlex direction={FlexDirection.column}>
        <AcFlex className="task-comments-header">
          <ac-icon icon={IconName.weeChat} class="ac-spacing-right-sm" />
          <AcText
            uppercase={true}
            weight={TextWeight.Semibold}
            size={TextSize.Main2}
          >
            {t('TASK_MANAGEMENT.TASK_PREVIEW.COMMENTS.TITLE')}
          </AcText>
        </AcFlex>
        <CommentsState task={props.task} />
      </AcFlex>
    </AcTileShell>
  );
};

export default Comments;
