import appSagas from '@hkm/components/App/domain/sagas';
import imagesSagas from '@hkm/components/App/imageQueue/domain/sagas';
import signalRSagas from '@hkm/components/App/notificationConsumer/domain/sagas';
import attendantAssignmentDataStateSaga from '@hkm/components/Attendant/shared/domain/saga';
import housekeepingBatchStatusSagas from '@hkm/components/Housekeeping/Batch/domain/sagas';
import housekeepingDashboardSagas from '@hkm/components/Housekeeping/Dashboard/domain/sagas';
import housekeepingDetailsSagas from '@hkm/components/Housekeeping/Details/domain/sagas';
import housekeepingStatisticsSagas from '@hkm/components/Housekeeping/Statistics/domain/sagas';
import maintenanceDashboardSagas from '@hkm/components/Maintenance/Dashboard/domain/sagas';
import maintenanceDetailsSagas from '@hkm/components/Maintenance/Details/domain/sagas';
import postingDashboardSagas from '@hkm/components/Posting/Dashboard/domain/sagas';
import postingViewSagas from '@hkm/components/Posting/PostingView/domain/sagas';
import postingRoomSagas from '@hkm/components/Posting/shared/domain/sagas';
import maintenanceAttachmentsTileSagas from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/sagas';
import maintenanceCreateSagas from '@hkm/components/shared/Templates/Maintenance/MaintenanceCreateForm/domain/sagas';
import maintenanceUpdateDataSagas from '@hkm/components/shared/Templates/Maintenance/shared/domain/sagas';
import taskManagementDashboardSagas from '@hkm/components/TaskManagement/Dashboard/domain/sagas';
import taskManagementRelationsSagas from '@hkm/components/TaskManagement/relations/domain/sagas';
import taskManagementTaskDetailsSagas from '@hkm/components/TaskManagement/TaskPreview/domain/sagas';
import { all, fork } from 'redux-saga/effects';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* root(): any {
  yield all([
    fork(appSagas),
    fork(housekeepingDashboardSagas),
    fork(housekeepingStatisticsSagas),
    fork(housekeepingDetailsSagas),
    fork(housekeepingBatchStatusSagas),
    fork(maintenanceDashboardSagas),
    fork(maintenanceDetailsSagas),
    fork(maintenanceUpdateDataSagas),
    fork(maintenanceAttachmentsTileSagas),
    fork(maintenanceCreateSagas),
    fork(attendantAssignmentDataStateSaga),
    fork(postingDashboardSagas),
    fork(postingRoomSagas),
    fork(postingViewSagas),
    fork(imagesSagas),
    fork(signalRSagas),
    fork(taskManagementDashboardSagas),
    fork(taskManagementTaskDetailsSagas),
    fork(taskManagementRelationsSagas),
  ]);
}
