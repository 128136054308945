import React, { FC } from 'react';
import DashboardSort from '@hkm/components/TaskManagement/Dashboard/DashboardSort/DashboardSort';
import DashboardQuickFilterWrapper from '@hkm/components/TaskManagement/Dashboard/DashboardSubheader/DashboardQuickFilterWrapper/DashboardQuickFilterWrapper';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcTile } from '@ac/mobile-components/dist/components/tile';
import { AlignItems, FlexDirection } from '@ac/mobile-components/dist/enums';

const DashboardSubheader: FC = () => {
  return (
    <AcTile>
      <AcFlex
        className="ac-padding-sm"
        direction={FlexDirection.column}
        alignItems={AlignItems.flexEnd}
      >
        <DashboardQuickFilterWrapper />
        <DashboardSort />
      </AcFlex>
    </AcTile>
  );
};

export default DashboardSubheader;
