import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertyDateFormats } from '@hkm/components/Menu/PropertySelector/domain/selectors';

import { AcFieldDate } from '@ac/mobile-components/dist/components/field';
import {
  Childless,
  Styleable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import {
  formatTestSelector,
  genericMemo,
} from '@ac/mobile-components/dist/utils';
import {
  formFieldFactory,
  FormRenderProps,
  KeyTypeInObjectOrArray,
} from '@ac/react-infrastructure';

interface AdvancedFiltersDateProps<T> extends Childless, Styleable {
  testSelectorPrefix: string;
  formProps: FormRenderProps<T>;
  field: keyof T;
  label: string;
}

function AdvancedFiltersDate<T>(
  props: AdvancedFiltersDateProps<T>
): ReactElement {
  const FormField = useMemo(() => formFieldFactory<T>(), []);
  const formats = useSelector(selectPropertyDateFormats);

  function onChange(value: string) {
    props.formProps.form.change(
      props.field as Extract<keyof T, string>,
      value as unknown as T[Extract<keyof T, string>]
    );
  }

  return (
    <FormField valuePath={props.field as unknown as KeyTypeInObjectOrArray<T>}>
      <AcFieldDate
        value={props.formProps.values[props.field] as unknown as string}
        format={formats.shortDate}
        label={props.label}
        onChange={onChange}
        className={props.className}
        placeholder={formats.shortDate}
        testSelector={formatTestSelector(
          props.testSelectorPrefix,
          props.field as string
        )}
        outputUTC={true}
        style={props.style}
      />
    </FormField>
  );
}

export default genericMemo(AdvancedFiltersDate);
