import React, { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import { selectMaintenanceDashboardFilters } from '@hkm/components/Maintenance/Dashboard/domain/selectors';
import MaintenanceFiltersChips from '@hkm/components/Maintenance/shared/MaintenanceFiltersChips/MaintenanceFiltersChips';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import ConnectedFooter from '@hkm/components/shared/LayoutComponents/ConnectedFooter';

import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

import './MaintenanceDashboardFooter.css';

// tslint:disable-next-line:no-empty-interface
export type MaintenanceDashboardFooterProps = Childless;

const MaintenanceDashboardFooter: FC<MaintenanceDashboardFooterProps> = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectMaintenanceDashboardFilters);

  function onFiltersChange(newFilters: MaintenanceFiltersData) {
    dispatch(dashboardActions.pagination.setFilters(newFilters));
  }

  return (
    <ConnectedFooter className="maintenance-dashboard-footer" noPadding={true}>
      {filters && (
        <MaintenanceFiltersChips
          deleteKeys={true}
          filters={filters}
          excludeQuickFilters={true}
          className="maintenance-dashboard-footer-chips"
          onChange={onFiltersChange}
        />
      )}
    </ConnectedFooter>
  );
};

export default memo(MaintenanceDashboardFooter);
