import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import {
  selectMaintenanceDashboardFilters,
  selectMaintenanceDashboardIsLoading,
} from '@hkm/components/Maintenance/Dashboard/domain/selectors';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import QuickFilter from '@hkm/components/shared/QuickFilter/QuickFilter';
import { QuickFilterItem } from '@hkm/components/shared/QuickFilter/QuickFilterItem';
import { HousekeepingInoperationStates } from '@hkm/shared/consts/inoperationStates/housekeepingInoperationStates';

import { HousekeepingInoperationStatus } from '@ac/library-api';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';

// tslint:disable-next-line:no-empty-interface
export type HousekeepingDashboardQuickFilterProps = Styleable;

const MaintenanceDashboardQuickFilter: FC<
  HousekeepingDashboardQuickFilterProps
> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(selectMaintenanceDashboardFilters);
  const isLoading = useSelector(selectMaintenanceDashboardIsLoading);

  function onQuickFilterChange(states: HousekeepingInoperationStatus[]) {
    const newFilters: MaintenanceFiltersData = {
      ...filters,
      inoperationStates: states.length ? states : undefined,
    };
    dispatch(actions.pagination.setFilters(newFilters));
  }

  const filterItems: Array<QuickFilterItem<HousekeepingInoperationStatus>> =
    HousekeepingInoperationStates.map((state) => ({
      value: state,
      label: t(`GLOBAL.MAINTENANCE_STATUS.LONG.${state}`),
    }));

  return (
    <QuickFilter
      items={filterItems}
      active={filters?.inoperationStates}
      className={props.className}
      style={props.style}
      disabled={isLoading}
      onChange={onQuickFilterChange}
    />
  );
};

export default memo(MaintenanceDashboardQuickFilter);
