import * as appActions from '@hkm/components/App/domain/actions';
import * as actions from '@hkm/components/Maintenance/Dashboard/domain/actions';
import { MaintenanceDashboardState } from '@hkm/components/Maintenance/Dashboard/domain/interfaces';
import { MaintenanceSort } from '@hkm/components/Maintenance/shared/enum/maintenanceSort';
import { MaintenanceFiltersData } from '@hkm/components/Maintenance/shared/models/maintenanceFiltersData';
import { createPaginationReducers } from '@hkm/store/pagination/paginationReducersFactory';
import { MaintenanceRoom } from '@hkm/types/maintenance/models/MaintenanceRoom';
import { ReducerMap } from 'redux-actions';

import { MaintenanceDetailsDto } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

const initialState: MaintenanceDashboardState = {
  paginationState: {
    sort: MaintenanceSort.RoomNumber,
    filters: {},
  },
};

const reducerMap: ReducerMap<MaintenanceDashboardState, unknown> = {
  ...createPaginationReducers<
    MaintenanceDashboardState,
    MaintenanceDetailsDto,
    MaintenanceRoom,
    MaintenanceSort,
    MaintenanceFiltersData
  >('paginationState', actions.pagination),
  [appActions.clearAllViews]: () => ({ ...initialState }),
  [actions.replaceChangedMaintenance]: (
    state,
    action: Action<MaintenanceRoom>
  ) => ({
    ...state,
    paginationState: {
      ...state.paginationState,
      page: !state.paginationState.page
        ? undefined
        : {
            ...state.paginationState.page,
            results: state.paginationState.page?.results.map((maintenance) =>
              maintenance.id === action.payload.id
                ? action.payload
                : maintenance
            ),
          },
    },
  }),
};

export default handleActions(reducerMap, initialState);
