import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { FlexDirection } from '@ac/mobile-components/dist/enums';
import { Color, IconName } from '@ac/web-components';

import './Accordion.css';

interface ComponentProps {
  header: React.ReactNode;
  content: string;
}

const Accordion = (props: ComponentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const firstRender = useRef(true);

  useLayoutEffect(() => {
    const { current } = textRef;

    if (current) {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      setIsContentOverflowing(hasOverflow);
    }
  }, [textRef]);

  const toggleIsExpanded = useCallback(() => {
    firstRender.current = false;
    setIsExpanded((expanded) => {
      if (!expanded) {
        textRef.current?.classList.remove(
          'overflow-accordion-content-text-nowrap'
        );
      }

      if (expanded) {
        setTimeout(
          () =>
            textRef.current?.classList.add(
              'overflow-accordion-content-text-nowrap'
            ),
          500
        );
      }

      return !expanded;
    });
  }, []);

  return (
    <AcFlex className="overflow-accordion" direction={FlexDirection.column}>
      <AcFlex className="overflow-accordion-header" onClick={toggleIsExpanded}>
        <AcFlex grow={true}>{props.header}</AcFlex>
        {isContentOverflowing && (
          <ac-icon
            icon={isExpanded ? IconName.actionUp : IconName.actionDown}
            color={Color.primaryDark}
            class="overflow-accordion-header-icon"
          />
        )}
      </AcFlex>
      <AcFlex
        className={
          isExpanded
            ? 'overflow-accordion-expanded'
            : 'overflow-accordion-collapsed'
        }
      >
        <AcFlex className="overflow-accordion-content">
          <span
            className={`overflow-accordion-content-text ${
              firstRender.current
                ? 'overflow-accordion-content-text-nowrap'
                : ''
            }`}
            ref={textRef}
            data-test-selector="comment-content"
          >
            {props.content}
          </span>
        </AcFlex>
      </AcFlex>
    </AcFlex>
  );
};

export default memo(Accordion);
