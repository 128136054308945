import React, { FC, memo, ReactNode } from 'react';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { FlexDirection, TextSize } from '@ac/mobile-components/dist/enums';
import { Childless } from '@ac/mobile-components/dist/interfaces/componentProps';

interface Props extends Childless {
  upperText: ReactNode;
  lowerText: ReactNode;
}

const HeaderTwoLineTitle: FC<Props> = ({ upperText, lowerText }) => {
  return (
    <AcFlex direction={FlexDirection.column}>
      <AcText size={TextSize.Main2} equalLineHeight={true}>
        {upperText}
      </AcText>
      <AcText size={TextSize.H2} equalLineHeight={true}>
        {lowerText}
      </AcText>
    </AcFlex>
  );
};

export default memo(HeaderTwoLineTitle);
