import React, { FC, HTMLAttributes, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AcFlex } from '@ac/mobile-components/dist/components/flex';
import { AcText } from '@ac/mobile-components/dist/components/text';
import { AlignItems, TextSize } from '@ac/mobile-components/dist/enums';
import { Testable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';
import { IconName, Size } from '@ac/web-components';

interface Props extends HTMLAttributes<HTMLDivElement>, Testable {
  small?: boolean;
}

const DoNotMoveInfo: FC<Props> = ({ small, testSelector, ...rest }) => {
  const { t } = useTranslation();

  return (
    <AcFlex alignItems={AlignItems.center} {...rest}>
      <ac-icon icon={IconName.lock} size={Size.sm} />
      <AcText
        size={small ? TextSize.Main3 : TextSize.Main2}
        className="ac-spacing-left-sm"
        {...getTestSelectorProp(testSelector, 'doNotMove')}
      >
        {t('GLOBAL.DO_NOT_MOVE_RESERVATION')}
      </AcText>
    </AcFlex>
  );
};

export default memo(DoNotMoveInfo);
